/* Copyright 2012 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* no-babel-preset */

import { getLookupTableFactory } from "./core_utils.js";

const getGlyphsUnicode = getLookupTableFactory(function (t) {
  t.A = 0x0041;
  t.AE = 0x00c6;
  t.AEacute = 0x01fc;
  t.AEmacron = 0x01e2;
  t.AEsmall = 0xf7e6;
  t.Aacute = 0x00c1;
  t.Aacutesmall = 0xf7e1;
  t.Abreve = 0x0102;
  t.Abreveacute = 0x1eae;
  t.Abrevecyrillic = 0x04d0;
  t.Abrevedotbelow = 0x1eb6;
  t.Abrevegrave = 0x1eb0;
  t.Abrevehookabove = 0x1eb2;
  t.Abrevetilde = 0x1eb4;
  t.Acaron = 0x01cd;
  t.Acircle = 0x24b6;
  t.Acircumflex = 0x00c2;
  t.Acircumflexacute = 0x1ea4;
  t.Acircumflexdotbelow = 0x1eac;
  t.Acircumflexgrave = 0x1ea6;
  t.Acircumflexhookabove = 0x1ea8;
  t.Acircumflexsmall = 0xf7e2;
  t.Acircumflextilde = 0x1eaa;
  t.Acute = 0xf6c9;
  t.Acutesmall = 0xf7b4;
  t.Acyrillic = 0x0410;
  t.Adblgrave = 0x0200;
  t.Adieresis = 0x00c4;
  t.Adieresiscyrillic = 0x04d2;
  t.Adieresismacron = 0x01de;
  t.Adieresissmall = 0xf7e4;
  t.Adotbelow = 0x1ea0;
  t.Adotmacron = 0x01e0;
  t.Agrave = 0x00c0;
  t.Agravesmall = 0xf7e0;
  t.Ahookabove = 0x1ea2;
  t.Aiecyrillic = 0x04d4;
  t.Ainvertedbreve = 0x0202;
  t.Alpha = 0x0391;
  t.Alphatonos = 0x0386;
  t.Amacron = 0x0100;
  t.Amonospace = 0xff21;
  t.Aogonek = 0x0104;
  t.Aring = 0x00c5;
  t.Aringacute = 0x01fa;
  t.Aringbelow = 0x1e00;
  t.Aringsmall = 0xf7e5;
  t.Asmall = 0xf761;
  t.Atilde = 0x00c3;
  t.Atildesmall = 0xf7e3;
  t.Aybarmenian = 0x0531;
  t.B = 0x0042;
  t.Bcircle = 0x24b7;
  t.Bdotaccent = 0x1e02;
  t.Bdotbelow = 0x1e04;
  t.Becyrillic = 0x0411;
  t.Benarmenian = 0x0532;
  t.Beta = 0x0392;
  t.Bhook = 0x0181;
  t.Blinebelow = 0x1e06;
  t.Bmonospace = 0xff22;
  t.Brevesmall = 0xf6f4;
  t.Bsmall = 0xf762;
  t.Btopbar = 0x0182;
  t.C = 0x0043;
  t.Caarmenian = 0x053e;
  t.Cacute = 0x0106;
  t.Caron = 0xf6ca;
  t.Caronsmall = 0xf6f5;
  t.Ccaron = 0x010c;
  t.Ccedilla = 0x00c7;
  t.Ccedillaacute = 0x1e08;
  t.Ccedillasmall = 0xf7e7;
  t.Ccircle = 0x24b8;
  t.Ccircumflex = 0x0108;
  t.Cdot = 0x010a;
  t.Cdotaccent = 0x010a;
  t.Cedillasmall = 0xf7b8;
  t.Chaarmenian = 0x0549;
  t.Cheabkhasiancyrillic = 0x04bc;
  t.Checyrillic = 0x0427;
  t.Chedescenderabkhasiancyrillic = 0x04be;
  t.Chedescendercyrillic = 0x04b6;
  t.Chedieresiscyrillic = 0x04f4;
  t.Cheharmenian = 0x0543;
  t.Chekhakassiancyrillic = 0x04cb;
  t.Cheverticalstrokecyrillic = 0x04b8;
  t.Chi = 0x03a7;
  t.Chook = 0x0187;
  t.Circumflexsmall = 0xf6f6;
  t.Cmonospace = 0xff23;
  t.Coarmenian = 0x0551;
  t.Csmall = 0xf763;
  t.D = 0x0044;
  t.DZ = 0x01f1;
  t.DZcaron = 0x01c4;
  t.Daarmenian = 0x0534;
  t.Dafrican = 0x0189;
  t.Dcaron = 0x010e;
  t.Dcedilla = 0x1e10;
  t.Dcircle = 0x24b9;
  t.Dcircumflexbelow = 0x1e12;
  t.Dcroat = 0x0110;
  t.Ddotaccent = 0x1e0a;
  t.Ddotbelow = 0x1e0c;
  t.Decyrillic = 0x0414;
  t.Deicoptic = 0x03ee;
  t.Delta = 0x2206;
  t.Deltagreek = 0x0394;
  t.Dhook = 0x018a;
  t.Dieresis = 0xf6cb;
  t.DieresisAcute = 0xf6cc;
  t.DieresisGrave = 0xf6cd;
  t.Dieresissmall = 0xf7a8;
  t.Digammagreek = 0x03dc;
  t.Djecyrillic = 0x0402;
  t.Dlinebelow = 0x1e0e;
  t.Dmonospace = 0xff24;
  t.Dotaccentsmall = 0xf6f7;
  t.Dslash = 0x0110;
  t.Dsmall = 0xf764;
  t.Dtopbar = 0x018b;
  t.Dz = 0x01f2;
  t.Dzcaron = 0x01c5;
  t.Dzeabkhasiancyrillic = 0x04e0;
  t.Dzecyrillic = 0x0405;
  t.Dzhecyrillic = 0x040f;
  t.E = 0x0045;
  t.Eacute = 0x00c9;
  t.Eacutesmall = 0xf7e9;
  t.Ebreve = 0x0114;
  t.Ecaron = 0x011a;
  t.Ecedillabreve = 0x1e1c;
  t.Echarmenian = 0x0535;
  t.Ecircle = 0x24ba;
  t.Ecircumflex = 0x00ca;
  t.Ecircumflexacute = 0x1ebe;
  t.Ecircumflexbelow = 0x1e18;
  t.Ecircumflexdotbelow = 0x1ec6;
  t.Ecircumflexgrave = 0x1ec0;
  t.Ecircumflexhookabove = 0x1ec2;
  t.Ecircumflexsmall = 0xf7ea;
  t.Ecircumflextilde = 0x1ec4;
  t.Ecyrillic = 0x0404;
  t.Edblgrave = 0x0204;
  t.Edieresis = 0x00cb;
  t.Edieresissmall = 0xf7eb;
  t.Edot = 0x0116;
  t.Edotaccent = 0x0116;
  t.Edotbelow = 0x1eb8;
  t.Efcyrillic = 0x0424;
  t.Egrave = 0x00c8;
  t.Egravesmall = 0xf7e8;
  t.Eharmenian = 0x0537;
  t.Ehookabove = 0x1eba;
  t.Eightroman = 0x2167;
  t.Einvertedbreve = 0x0206;
  t.Eiotifiedcyrillic = 0x0464;
  t.Elcyrillic = 0x041b;
  t.Elevenroman = 0x216a;
  t.Emacron = 0x0112;
  t.Emacronacute = 0x1e16;
  t.Emacrongrave = 0x1e14;
  t.Emcyrillic = 0x041c;
  t.Emonospace = 0xff25;
  t.Encyrillic = 0x041d;
  t.Endescendercyrillic = 0x04a2;
  t.Eng = 0x014a;
  t.Enghecyrillic = 0x04a4;
  t.Enhookcyrillic = 0x04c7;
  t.Eogonek = 0x0118;
  t.Eopen = 0x0190;
  t.Epsilon = 0x0395;
  t.Epsilontonos = 0x0388;
  t.Ercyrillic = 0x0420;
  t.Ereversed = 0x018e;
  t.Ereversedcyrillic = 0x042d;
  t.Escyrillic = 0x0421;
  t.Esdescendercyrillic = 0x04aa;
  t.Esh = 0x01a9;
  t.Esmall = 0xf765;
  t.Eta = 0x0397;
  t.Etarmenian = 0x0538;
  t.Etatonos = 0x0389;
  t.Eth = 0x00d0;
  t.Ethsmall = 0xf7f0;
  t.Etilde = 0x1ebc;
  t.Etildebelow = 0x1e1a;
  t.Euro = 0x20ac;
  t.Ezh = 0x01b7;
  t.Ezhcaron = 0x01ee;
  t.Ezhreversed = 0x01b8;
  t.F = 0x0046;
  t.Fcircle = 0x24bb;
  t.Fdotaccent = 0x1e1e;
  t.Feharmenian = 0x0556;
  t.Feicoptic = 0x03e4;
  t.Fhook = 0x0191;
  t.Fitacyrillic = 0x0472;
  t.Fiveroman = 0x2164;
  t.Fmonospace = 0xff26;
  t.Fourroman = 0x2163;
  t.Fsmall = 0xf766;
  t.G = 0x0047;
  t.GBsquare = 0x3387;
  t.Gacute = 0x01f4;
  t.Gamma = 0x0393;
  t.Gammaafrican = 0x0194;
  t.Gangiacoptic = 0x03ea;
  t.Gbreve = 0x011e;
  t.Gcaron = 0x01e6;
  t.Gcedilla = 0x0122;
  t.Gcircle = 0x24bc;
  t.Gcircumflex = 0x011c;
  t.Gcommaaccent = 0x0122;
  t.Gdot = 0x0120;
  t.Gdotaccent = 0x0120;
  t.Gecyrillic = 0x0413;
  t.Ghadarmenian = 0x0542;
  t.Ghemiddlehookcyrillic = 0x0494;
  t.Ghestrokecyrillic = 0x0492;
  t.Gheupturncyrillic = 0x0490;
  t.Ghook = 0x0193;
  t.Gimarmenian = 0x0533;
  t.Gjecyrillic = 0x0403;
  t.Gmacron = 0x1e20;
  t.Gmonospace = 0xff27;
  t.Grave = 0xf6ce;
  t.Gravesmall = 0xf760;
  t.Gsmall = 0xf767;
  t.Gsmallhook = 0x029b;
  t.Gstroke = 0x01e4;
  t.H = 0x0048;
  t.H18533 = 0x25cf;
  t.H18543 = 0x25aa;
  t.H18551 = 0x25ab;
  t.H22073 = 0x25a1;
  t.HPsquare = 0x33cb;
  t.Haabkhasiancyrillic = 0x04a8;
  t.Hadescendercyrillic = 0x04b2;
  t.Hardsigncyrillic = 0x042a;
  t.Hbar = 0x0126;
  t.Hbrevebelow = 0x1e2a;
  t.Hcedilla = 0x1e28;
  t.Hcircle = 0x24bd;
  t.Hcircumflex = 0x0124;
  t.Hdieresis = 0x1e26;
  t.Hdotaccent = 0x1e22;
  t.Hdotbelow = 0x1e24;
  t.Hmonospace = 0xff28;
  t.Hoarmenian = 0x0540;
  t.Horicoptic = 0x03e8;
  t.Hsmall = 0xf768;
  t.Hungarumlaut = 0xf6cf;
  t.Hungarumlautsmall = 0xf6f8;
  t.Hzsquare = 0x3390;
  t.I = 0x0049;
  t.IAcyrillic = 0x042f;
  t.IJ = 0x0132;
  t.IUcyrillic = 0x042e;
  t.Iacute = 0x00cd;
  t.Iacutesmall = 0xf7ed;
  t.Ibreve = 0x012c;
  t.Icaron = 0x01cf;
  t.Icircle = 0x24be;
  t.Icircumflex = 0x00ce;
  t.Icircumflexsmall = 0xf7ee;
  t.Icyrillic = 0x0406;
  t.Idblgrave = 0x0208;
  t.Idieresis = 0x00cf;
  t.Idieresisacute = 0x1e2e;
  t.Idieresiscyrillic = 0x04e4;
  t.Idieresissmall = 0xf7ef;
  t.Idot = 0x0130;
  t.Idotaccent = 0x0130;
  t.Idotbelow = 0x1eca;
  t.Iebrevecyrillic = 0x04d6;
  t.Iecyrillic = 0x0415;
  t.Ifraktur = 0x2111;
  t.Igrave = 0x00cc;
  t.Igravesmall = 0xf7ec;
  t.Ihookabove = 0x1ec8;
  t.Iicyrillic = 0x0418;
  t.Iinvertedbreve = 0x020a;
  t.Iishortcyrillic = 0x0419;
  t.Imacron = 0x012a;
  t.Imacroncyrillic = 0x04e2;
  t.Imonospace = 0xff29;
  t.Iniarmenian = 0x053b;
  t.Iocyrillic = 0x0401;
  t.Iogonek = 0x012e;
  t.Iota = 0x0399;
  t.Iotaafrican = 0x0196;
  t.Iotadieresis = 0x03aa;
  t.Iotatonos = 0x038a;
  t.Ismall = 0xf769;
  t.Istroke = 0x0197;
  t.Itilde = 0x0128;
  t.Itildebelow = 0x1e2c;
  t.Izhitsacyrillic = 0x0474;
  t.Izhitsadblgravecyrillic = 0x0476;
  t.J = 0x004a;
  t.Jaarmenian = 0x0541;
  t.Jcircle = 0x24bf;
  t.Jcircumflex = 0x0134;
  t.Jecyrillic = 0x0408;
  t.Jheharmenian = 0x054b;
  t.Jmonospace = 0xff2a;
  t.Jsmall = 0xf76a;
  t.K = 0x004b;
  t.KBsquare = 0x3385;
  t.KKsquare = 0x33cd;
  t.Kabashkircyrillic = 0x04a0;
  t.Kacute = 0x1e30;
  t.Kacyrillic = 0x041a;
  t.Kadescendercyrillic = 0x049a;
  t.Kahookcyrillic = 0x04c3;
  t.Kappa = 0x039a;
  t.Kastrokecyrillic = 0x049e;
  t.Kaverticalstrokecyrillic = 0x049c;
  t.Kcaron = 0x01e8;
  t.Kcedilla = 0x0136;
  t.Kcircle = 0x24c0;
  t.Kcommaaccent = 0x0136;
  t.Kdotbelow = 0x1e32;
  t.Keharmenian = 0x0554;
  t.Kenarmenian = 0x053f;
  t.Khacyrillic = 0x0425;
  t.Kheicoptic = 0x03e6;
  t.Khook = 0x0198;
  t.Kjecyrillic = 0x040c;
  t.Klinebelow = 0x1e34;
  t.Kmonospace = 0xff2b;
  t.Koppacyrillic = 0x0480;
  t.Koppagreek = 0x03de;
  t.Ksicyrillic = 0x046e;
  t.Ksmall = 0xf76b;
  t.L = 0x004c;
  t.LJ = 0x01c7;
  t.LL = 0xf6bf;
  t.Lacute = 0x0139;
  t.Lambda = 0x039b;
  t.Lcaron = 0x013d;
  t.Lcedilla = 0x013b;
  t.Lcircle = 0x24c1;
  t.Lcircumflexbelow = 0x1e3c;
  t.Lcommaaccent = 0x013b;
  t.Ldot = 0x013f;
  t.Ldotaccent = 0x013f;
  t.Ldotbelow = 0x1e36;
  t.Ldotbelowmacron = 0x1e38;
  t.Liwnarmenian = 0x053c;
  t.Lj = 0x01c8;
  t.Ljecyrillic = 0x0409;
  t.Llinebelow = 0x1e3a;
  t.Lmonospace = 0xff2c;
  t.Lslash = 0x0141;
  t.Lslashsmall = 0xf6f9;
  t.Lsmall = 0xf76c;
  t.M = 0x004d;
  t.MBsquare = 0x3386;
  t.Macron = 0xf6d0;
  t.Macronsmall = 0xf7af;
  t.Macute = 0x1e3e;
  t.Mcircle = 0x24c2;
  t.Mdotaccent = 0x1e40;
  t.Mdotbelow = 0x1e42;
  t.Menarmenian = 0x0544;
  t.Mmonospace = 0xff2d;
  t.Msmall = 0xf76d;
  t.Mturned = 0x019c;
  t.Mu = 0x039c;
  t.N = 0x004e;
  t.NJ = 0x01ca;
  t.Nacute = 0x0143;
  t.Ncaron = 0x0147;
  t.Ncedilla = 0x0145;
  t.Ncircle = 0x24c3;
  t.Ncircumflexbelow = 0x1e4a;
  t.Ncommaaccent = 0x0145;
  t.Ndotaccent = 0x1e44;
  t.Ndotbelow = 0x1e46;
  t.Nhookleft = 0x019d;
  t.Nineroman = 0x2168;
  t.Nj = 0x01cb;
  t.Njecyrillic = 0x040a;
  t.Nlinebelow = 0x1e48;
  t.Nmonospace = 0xff2e;
  t.Nowarmenian = 0x0546;
  t.Nsmall = 0xf76e;
  t.Ntilde = 0x00d1;
  t.Ntildesmall = 0xf7f1;
  t.Nu = 0x039d;
  t.O = 0x004f;
  t.OE = 0x0152;
  t.OEsmall = 0xf6fa;
  t.Oacute = 0x00d3;
  t.Oacutesmall = 0xf7f3;
  t.Obarredcyrillic = 0x04e8;
  t.Obarreddieresiscyrillic = 0x04ea;
  t.Obreve = 0x014e;
  t.Ocaron = 0x01d1;
  t.Ocenteredtilde = 0x019f;
  t.Ocircle = 0x24c4;
  t.Ocircumflex = 0x00d4;
  t.Ocircumflexacute = 0x1ed0;
  t.Ocircumflexdotbelow = 0x1ed8;
  t.Ocircumflexgrave = 0x1ed2;
  t.Ocircumflexhookabove = 0x1ed4;
  t.Ocircumflexsmall = 0xf7f4;
  t.Ocircumflextilde = 0x1ed6;
  t.Ocyrillic = 0x041e;
  t.Odblacute = 0x0150;
  t.Odblgrave = 0x020c;
  t.Odieresis = 0x00d6;
  t.Odieresiscyrillic = 0x04e6;
  t.Odieresissmall = 0xf7f6;
  t.Odotbelow = 0x1ecc;
  t.Ogoneksmall = 0xf6fb;
  t.Ograve = 0x00d2;
  t.Ogravesmall = 0xf7f2;
  t.Oharmenian = 0x0555;
  t.Ohm = 0x2126;
  t.Ohookabove = 0x1ece;
  t.Ohorn = 0x01a0;
  t.Ohornacute = 0x1eda;
  t.Ohorndotbelow = 0x1ee2;
  t.Ohorngrave = 0x1edc;
  t.Ohornhookabove = 0x1ede;
  t.Ohorntilde = 0x1ee0;
  t.Ohungarumlaut = 0x0150;
  t.Oi = 0x01a2;
  t.Oinvertedbreve = 0x020e;
  t.Omacron = 0x014c;
  t.Omacronacute = 0x1e52;
  t.Omacrongrave = 0x1e50;
  t.Omega = 0x2126;
  t.Omegacyrillic = 0x0460;
  t.Omegagreek = 0x03a9;
  t.Omegaroundcyrillic = 0x047a;
  t.Omegatitlocyrillic = 0x047c;
  t.Omegatonos = 0x038f;
  t.Omicron = 0x039f;
  t.Omicrontonos = 0x038c;
  t.Omonospace = 0xff2f;
  t.Oneroman = 0x2160;
  t.Oogonek = 0x01ea;
  t.Oogonekmacron = 0x01ec;
  t.Oopen = 0x0186;
  t.Oslash = 0x00d8;
  t.Oslashacute = 0x01fe;
  t.Oslashsmall = 0xf7f8;
  t.Osmall = 0xf76f;
  t.Ostrokeacute = 0x01fe;
  t.Otcyrillic = 0x047e;
  t.Otilde = 0x00d5;
  t.Otildeacute = 0x1e4c;
  t.Otildedieresis = 0x1e4e;
  t.Otildesmall = 0xf7f5;
  t.P = 0x0050;
  t.Pacute = 0x1e54;
  t.Pcircle = 0x24c5;
  t.Pdotaccent = 0x1e56;
  t.Pecyrillic = 0x041f;
  t.Peharmenian = 0x054a;
  t.Pemiddlehookcyrillic = 0x04a6;
  t.Phi = 0x03a6;
  t.Phook = 0x01a4;
  t.Pi = 0x03a0;
  t.Piwrarmenian = 0x0553;
  t.Pmonospace = 0xff30;
  t.Psi = 0x03a8;
  t.Psicyrillic = 0x0470;
  t.Psmall = 0xf770;
  t.Q = 0x0051;
  t.Qcircle = 0x24c6;
  t.Qmonospace = 0xff31;
  t.Qsmall = 0xf771;
  t.R = 0x0052;
  t.Raarmenian = 0x054c;
  t.Racute = 0x0154;
  t.Rcaron = 0x0158;
  t.Rcedilla = 0x0156;
  t.Rcircle = 0x24c7;
  t.Rcommaaccent = 0x0156;
  t.Rdblgrave = 0x0210;
  t.Rdotaccent = 0x1e58;
  t.Rdotbelow = 0x1e5a;
  t.Rdotbelowmacron = 0x1e5c;
  t.Reharmenian = 0x0550;
  t.Rfraktur = 0x211c;
  t.Rho = 0x03a1;
  t.Ringsmall = 0xf6fc;
  t.Rinvertedbreve = 0x0212;
  t.Rlinebelow = 0x1e5e;
  t.Rmonospace = 0xff32;
  t.Rsmall = 0xf772;
  t.Rsmallinverted = 0x0281;
  t.Rsmallinvertedsuperior = 0x02b6;
  t.S = 0x0053;
  t.SF010000 = 0x250c;
  t.SF020000 = 0x2514;
  t.SF030000 = 0x2510;
  t.SF040000 = 0x2518;
  t.SF050000 = 0x253c;
  t.SF060000 = 0x252c;
  t.SF070000 = 0x2534;
  t.SF080000 = 0x251c;
  t.SF090000 = 0x2524;
  t.SF100000 = 0x2500;
  t.SF110000 = 0x2502;
  t.SF190000 = 0x2561;
  t.SF200000 = 0x2562;
  t.SF210000 = 0x2556;
  t.SF220000 = 0x2555;
  t.SF230000 = 0x2563;
  t.SF240000 = 0x2551;
  t.SF250000 = 0x2557;
  t.SF260000 = 0x255d;
  t.SF270000 = 0x255c;
  t.SF280000 = 0x255b;
  t.SF360000 = 0x255e;
  t.SF370000 = 0x255f;
  t.SF380000 = 0x255a;
  t.SF390000 = 0x2554;
  t.SF400000 = 0x2569;
  t.SF410000 = 0x2566;
  t.SF420000 = 0x2560;
  t.SF430000 = 0x2550;
  t.SF440000 = 0x256c;
  t.SF450000 = 0x2567;
  t.SF460000 = 0x2568;
  t.SF470000 = 0x2564;
  t.SF480000 = 0x2565;
  t.SF490000 = 0x2559;
  t.SF500000 = 0x2558;
  t.SF510000 = 0x2552;
  t.SF520000 = 0x2553;
  t.SF530000 = 0x256b;
  t.SF540000 = 0x256a;
  t.Sacute = 0x015a;
  t.Sacutedotaccent = 0x1e64;
  t.Sampigreek = 0x03e0;
  t.Scaron = 0x0160;
  t.Scarondotaccent = 0x1e66;
  t.Scaronsmall = 0xf6fd;
  t.Scedilla = 0x015e;
  t.Schwa = 0x018f;
  t.Schwacyrillic = 0x04d8;
  t.Schwadieresiscyrillic = 0x04da;
  t.Scircle = 0x24c8;
  t.Scircumflex = 0x015c;
  t.Scommaaccent = 0x0218;
  t.Sdotaccent = 0x1e60;
  t.Sdotbelow = 0x1e62;
  t.Sdotbelowdotaccent = 0x1e68;
  t.Seharmenian = 0x054d;
  t.Sevenroman = 0x2166;
  t.Shaarmenian = 0x0547;
  t.Shacyrillic = 0x0428;
  t.Shchacyrillic = 0x0429;
  t.Sheicoptic = 0x03e2;
  t.Shhacyrillic = 0x04ba;
  t.Shimacoptic = 0x03ec;
  t.Sigma = 0x03a3;
  t.Sixroman = 0x2165;
  t.Smonospace = 0xff33;
  t.Softsigncyrillic = 0x042c;
  t.Ssmall = 0xf773;
  t.Stigmagreek = 0x03da;
  t.T = 0x0054;
  t.Tau = 0x03a4;
  t.Tbar = 0x0166;
  t.Tcaron = 0x0164;
  t.Tcedilla = 0x0162;
  t.Tcircle = 0x24c9;
  t.Tcircumflexbelow = 0x1e70;
  t.Tcommaaccent = 0x0162;
  t.Tdotaccent = 0x1e6a;
  t.Tdotbelow = 0x1e6c;
  t.Tecyrillic = 0x0422;
  t.Tedescendercyrillic = 0x04ac;
  t.Tenroman = 0x2169;
  t.Tetsecyrillic = 0x04b4;
  t.Theta = 0x0398;
  t.Thook = 0x01ac;
  t.Thorn = 0x00de;
  t.Thornsmall = 0xf7fe;
  t.Threeroman = 0x2162;
  t.Tildesmall = 0xf6fe;
  t.Tiwnarmenian = 0x054f;
  t.Tlinebelow = 0x1e6e;
  t.Tmonospace = 0xff34;
  t.Toarmenian = 0x0539;
  t.Tonefive = 0x01bc;
  t.Tonesix = 0x0184;
  t.Tonetwo = 0x01a7;
  t.Tretroflexhook = 0x01ae;
  t.Tsecyrillic = 0x0426;
  t.Tshecyrillic = 0x040b;
  t.Tsmall = 0xf774;
  t.Twelveroman = 0x216b;
  t.Tworoman = 0x2161;
  t.U = 0x0055;
  t.Uacute = 0x00da;
  t.Uacutesmall = 0xf7fa;
  t.Ubreve = 0x016c;
  t.Ucaron = 0x01d3;
  t.Ucircle = 0x24ca;
  t.Ucircumflex = 0x00db;
  t.Ucircumflexbelow = 0x1e76;
  t.Ucircumflexsmall = 0xf7fb;
  t.Ucyrillic = 0x0423;
  t.Udblacute = 0x0170;
  t.Udblgrave = 0x0214;
  t.Udieresis = 0x00dc;
  t.Udieresisacute = 0x01d7;
  t.Udieresisbelow = 0x1e72;
  t.Udieresiscaron = 0x01d9;
  t.Udieresiscyrillic = 0x04f0;
  t.Udieresisgrave = 0x01db;
  t.Udieresismacron = 0x01d5;
  t.Udieresissmall = 0xf7fc;
  t.Udotbelow = 0x1ee4;
  t.Ugrave = 0x00d9;
  t.Ugravesmall = 0xf7f9;
  t.Uhookabove = 0x1ee6;
  t.Uhorn = 0x01af;
  t.Uhornacute = 0x1ee8;
  t.Uhorndotbelow = 0x1ef0;
  t.Uhorngrave = 0x1eea;
  t.Uhornhookabove = 0x1eec;
  t.Uhorntilde = 0x1eee;
  t.Uhungarumlaut = 0x0170;
  t.Uhungarumlautcyrillic = 0x04f2;
  t.Uinvertedbreve = 0x0216;
  t.Ukcyrillic = 0x0478;
  t.Umacron = 0x016a;
  t.Umacroncyrillic = 0x04ee;
  t.Umacrondieresis = 0x1e7a;
  t.Umonospace = 0xff35;
  t.Uogonek = 0x0172;
  t.Upsilon = 0x03a5;
  t.Upsilon1 = 0x03d2;
  t.Upsilonacutehooksymbolgreek = 0x03d3;
  t.Upsilonafrican = 0x01b1;
  t.Upsilondieresis = 0x03ab;
  t.Upsilondieresishooksymbolgreek = 0x03d4;
  t.Upsilonhooksymbol = 0x03d2;
  t.Upsilontonos = 0x038e;
  t.Uring = 0x016e;
  t.Ushortcyrillic = 0x040e;
  t.Usmall = 0xf775;
  t.Ustraightcyrillic = 0x04ae;
  t.Ustraightstrokecyrillic = 0x04b0;
  t.Utilde = 0x0168;
  t.Utildeacute = 0x1e78;
  t.Utildebelow = 0x1e74;
  t.V = 0x0056;
  t.Vcircle = 0x24cb;
  t.Vdotbelow = 0x1e7e;
  t.Vecyrillic = 0x0412;
  t.Vewarmenian = 0x054e;
  t.Vhook = 0x01b2;
  t.Vmonospace = 0xff36;
  t.Voarmenian = 0x0548;
  t.Vsmall = 0xf776;
  t.Vtilde = 0x1e7c;
  t.W = 0x0057;
  t.Wacute = 0x1e82;
  t.Wcircle = 0x24cc;
  t.Wcircumflex = 0x0174;
  t.Wdieresis = 0x1e84;
  t.Wdotaccent = 0x1e86;
  t.Wdotbelow = 0x1e88;
  t.Wgrave = 0x1e80;
  t.Wmonospace = 0xff37;
  t.Wsmall = 0xf777;
  t.X = 0x0058;
  t.Xcircle = 0x24cd;
  t.Xdieresis = 0x1e8c;
  t.Xdotaccent = 0x1e8a;
  t.Xeharmenian = 0x053d;
  t.Xi = 0x039e;
  t.Xmonospace = 0xff38;
  t.Xsmall = 0xf778;
  t.Y = 0x0059;
  t.Yacute = 0x00dd;
  t.Yacutesmall = 0xf7fd;
  t.Yatcyrillic = 0x0462;
  t.Ycircle = 0x24ce;
  t.Ycircumflex = 0x0176;
  t.Ydieresis = 0x0178;
  t.Ydieresissmall = 0xf7ff;
  t.Ydotaccent = 0x1e8e;
  t.Ydotbelow = 0x1ef4;
  t.Yericyrillic = 0x042b;
  t.Yerudieresiscyrillic = 0x04f8;
  t.Ygrave = 0x1ef2;
  t.Yhook = 0x01b3;
  t.Yhookabove = 0x1ef6;
  t.Yiarmenian = 0x0545;
  t.Yicyrillic = 0x0407;
  t.Yiwnarmenian = 0x0552;
  t.Ymonospace = 0xff39;
  t.Ysmall = 0xf779;
  t.Ytilde = 0x1ef8;
  t.Yusbigcyrillic = 0x046a;
  t.Yusbigiotifiedcyrillic = 0x046c;
  t.Yuslittlecyrillic = 0x0466;
  t.Yuslittleiotifiedcyrillic = 0x0468;
  t.Z = 0x005a;
  t.Zaarmenian = 0x0536;
  t.Zacute = 0x0179;
  t.Zcaron = 0x017d;
  t.Zcaronsmall = 0xf6ff;
  t.Zcircle = 0x24cf;
  t.Zcircumflex = 0x1e90;
  t.Zdot = 0x017b;
  t.Zdotaccent = 0x017b;
  t.Zdotbelow = 0x1e92;
  t.Zecyrillic = 0x0417;
  t.Zedescendercyrillic = 0x0498;
  t.Zedieresiscyrillic = 0x04de;
  t.Zeta = 0x0396;
  t.Zhearmenian = 0x053a;
  t.Zhebrevecyrillic = 0x04c1;
  t.Zhecyrillic = 0x0416;
  t.Zhedescendercyrillic = 0x0496;
  t.Zhedieresiscyrillic = 0x04dc;
  t.Zlinebelow = 0x1e94;
  t.Zmonospace = 0xff3a;
  t.Zsmall = 0xf77a;
  t.Zstroke = 0x01b5;
  t.a = 0x0061;
  t.aabengali = 0x0986;
  t.aacute = 0x00e1;
  t.aadeva = 0x0906;
  t.aagujarati = 0x0a86;
  t.aagurmukhi = 0x0a06;
  t.aamatragurmukhi = 0x0a3e;
  t.aarusquare = 0x3303;
  t.aavowelsignbengali = 0x09be;
  t.aavowelsigndeva = 0x093e;
  t.aavowelsigngujarati = 0x0abe;
  t.abbreviationmarkarmenian = 0x055f;
  t.abbreviationsigndeva = 0x0970;
  t.abengali = 0x0985;
  t.abopomofo = 0x311a;
  t.abreve = 0x0103;
  t.abreveacute = 0x1eaf;
  t.abrevecyrillic = 0x04d1;
  t.abrevedotbelow = 0x1eb7;
  t.abrevegrave = 0x1eb1;
  t.abrevehookabove = 0x1eb3;
  t.abrevetilde = 0x1eb5;
  t.acaron = 0x01ce;
  t.acircle = 0x24d0;
  t.acircumflex = 0x00e2;
  t.acircumflexacute = 0x1ea5;
  t.acircumflexdotbelow = 0x1ead;
  t.acircumflexgrave = 0x1ea7;
  t.acircumflexhookabove = 0x1ea9;
  t.acircumflextilde = 0x1eab;
  t.acute = 0x00b4;
  t.acutebelowcmb = 0x0317;
  t.acutecmb = 0x0301;
  t.acutecomb = 0x0301;
  t.acutedeva = 0x0954;
  t.acutelowmod = 0x02cf;
  t.acutetonecmb = 0x0341;
  t.acyrillic = 0x0430;
  t.adblgrave = 0x0201;
  t.addakgurmukhi = 0x0a71;
  t.adeva = 0x0905;
  t.adieresis = 0x00e4;
  t.adieresiscyrillic = 0x04d3;
  t.adieresismacron = 0x01df;
  t.adotbelow = 0x1ea1;
  t.adotmacron = 0x01e1;
  t.ae = 0x00e6;
  t.aeacute = 0x01fd;
  t.aekorean = 0x3150;
  t.aemacron = 0x01e3;
  t.afii00208 = 0x2015;
  t.afii08941 = 0x20a4;
  t.afii10017 = 0x0410;
  t.afii10018 = 0x0411;
  t.afii10019 = 0x0412;
  t.afii10020 = 0x0413;
  t.afii10021 = 0x0414;
  t.afii10022 = 0x0415;
  t.afii10023 = 0x0401;
  t.afii10024 = 0x0416;
  t.afii10025 = 0x0417;
  t.afii10026 = 0x0418;
  t.afii10027 = 0x0419;
  t.afii10028 = 0x041a;
  t.afii10029 = 0x041b;
  t.afii10030 = 0x041c;
  t.afii10031 = 0x041d;
  t.afii10032 = 0x041e;
  t.afii10033 = 0x041f;
  t.afii10034 = 0x0420;
  t.afii10035 = 0x0421;
  t.afii10036 = 0x0422;
  t.afii10037 = 0x0423;
  t.afii10038 = 0x0424;
  t.afii10039 = 0x0425;
  t.afii10040 = 0x0426;
  t.afii10041 = 0x0427;
  t.afii10042 = 0x0428;
  t.afii10043 = 0x0429;
  t.afii10044 = 0x042a;
  t.afii10045 = 0x042b;
  t.afii10046 = 0x042c;
  t.afii10047 = 0x042d;
  t.afii10048 = 0x042e;
  t.afii10049 = 0x042f;
  t.afii10050 = 0x0490;
  t.afii10051 = 0x0402;
  t.afii10052 = 0x0403;
  t.afii10053 = 0x0404;
  t.afii10054 = 0x0405;
  t.afii10055 = 0x0406;
  t.afii10056 = 0x0407;
  t.afii10057 = 0x0408;
  t.afii10058 = 0x0409;
  t.afii10059 = 0x040a;
  t.afii10060 = 0x040b;
  t.afii10061 = 0x040c;
  t.afii10062 = 0x040e;
  t.afii10063 = 0xf6c4;
  t.afii10064 = 0xf6c5;
  t.afii10065 = 0x0430;
  t.afii10066 = 0x0431;
  t.afii10067 = 0x0432;
  t.afii10068 = 0x0433;
  t.afii10069 = 0x0434;
  t.afii10070 = 0x0435;
  t.afii10071 = 0x0451;
  t.afii10072 = 0x0436;
  t.afii10073 = 0x0437;
  t.afii10074 = 0x0438;
  t.afii10075 = 0x0439;
  t.afii10076 = 0x043a;
  t.afii10077 = 0x043b;
  t.afii10078 = 0x043c;
  t.afii10079 = 0x043d;
  t.afii10080 = 0x043e;
  t.afii10081 = 0x043f;
  t.afii10082 = 0x0440;
  t.afii10083 = 0x0441;
  t.afii10084 = 0x0442;
  t.afii10085 = 0x0443;
  t.afii10086 = 0x0444;
  t.afii10087 = 0x0445;
  t.afii10088 = 0x0446;
  t.afii10089 = 0x0447;
  t.afii10090 = 0x0448;
  t.afii10091 = 0x0449;
  t.afii10092 = 0x044a;
  t.afii10093 = 0x044b;
  t.afii10094 = 0x044c;
  t.afii10095 = 0x044d;
  t.afii10096 = 0x044e;
  t.afii10097 = 0x044f;
  t.afii10098 = 0x0491;
  t.afii10099 = 0x0452;
  t.afii10100 = 0x0453;
  t.afii10101 = 0x0454;
  t.afii10102 = 0x0455;
  t.afii10103 = 0x0456;
  t.afii10104 = 0x0457;
  t.afii10105 = 0x0458;
  t.afii10106 = 0x0459;
  t.afii10107 = 0x045a;
  t.afii10108 = 0x045b;
  t.afii10109 = 0x045c;
  t.afii10110 = 0x045e;
  t.afii10145 = 0x040f;
  t.afii10146 = 0x0462;
  t.afii10147 = 0x0472;
  t.afii10148 = 0x0474;
  t.afii10192 = 0xf6c6;
  t.afii10193 = 0x045f;
  t.afii10194 = 0x0463;
  t.afii10195 = 0x0473;
  t.afii10196 = 0x0475;
  t.afii10831 = 0xf6c7;
  t.afii10832 = 0xf6c8;
  t.afii10846 = 0x04d9;
  t.afii299 = 0x200e;
  t.afii300 = 0x200f;
  t.afii301 = 0x200d;
  t.afii57381 = 0x066a;
  t.afii57388 = 0x060c;
  t.afii57392 = 0x0660;
  t.afii57393 = 0x0661;
  t.afii57394 = 0x0662;
  t.afii57395 = 0x0663;
  t.afii57396 = 0x0664;
  t.afii57397 = 0x0665;
  t.afii57398 = 0x0666;
  t.afii57399 = 0x0667;
  t.afii57400 = 0x0668;
  t.afii57401 = 0x0669;
  t.afii57403 = 0x061b;
  t.afii57407 = 0x061f;
  t.afii57409 = 0x0621;
  t.afii57410 = 0x0622;
  t.afii57411 = 0x0623;
  t.afii57412 = 0x0624;
  t.afii57413 = 0x0625;
  t.afii57414 = 0x0626;
  t.afii57415 = 0x0627;
  t.afii57416 = 0x0628;
  t.afii57417 = 0x0629;
  t.afii57418 = 0x062a;
  t.afii57419 = 0x062b;
  t.afii57420 = 0x062c;
  t.afii57421 = 0x062d;
  t.afii57422 = 0x062e;
  t.afii57423 = 0x062f;
  t.afii57424 = 0x0630;
  t.afii57425 = 0x0631;
  t.afii57426 = 0x0632;
  t.afii57427 = 0x0633;
  t.afii57428 = 0x0634;
  t.afii57429 = 0x0635;
  t.afii57430 = 0x0636;
  t.afii57431 = 0x0637;
  t.afii57432 = 0x0638;
  t.afii57433 = 0x0639;
  t.afii57434 = 0x063a;
  t.afii57440 = 0x0640;
  t.afii57441 = 0x0641;
  t.afii57442 = 0x0642;
  t.afii57443 = 0x0643;
  t.afii57444 = 0x0644;
  t.afii57445 = 0x0645;
  t.afii57446 = 0x0646;
  t.afii57448 = 0x0648;
  t.afii57449 = 0x0649;
  t.afii57450 = 0x064a;
  t.afii57451 = 0x064b;
  t.afii57452 = 0x064c;
  t.afii57453 = 0x064d;
  t.afii57454 = 0x064e;
  t.afii57455 = 0x064f;
  t.afii57456 = 0x0650;
  t.afii57457 = 0x0651;
  t.afii57458 = 0x0652;
  t.afii57470 = 0x0647;
  t.afii57505 = 0x06a4;
  t.afii57506 = 0x067e;
  t.afii57507 = 0x0686;
  t.afii57508 = 0x0698;
  t.afii57509 = 0x06af;
  t.afii57511 = 0x0679;
  t.afii57512 = 0x0688;
  t.afii57513 = 0x0691;
  t.afii57514 = 0x06ba;
  t.afii57519 = 0x06d2;
  t.afii57534 = 0x06d5;
  t.afii57636 = 0x20aa;
  t.afii57645 = 0x05be;
  t.afii57658 = 0x05c3;
  t.afii57664 = 0x05d0;
  t.afii57665 = 0x05d1;
  t.afii57666 = 0x05d2;
  t.afii57667 = 0x05d3;
  t.afii57668 = 0x05d4;
  t.afii57669 = 0x05d5;
  t.afii57670 = 0x05d6;
  t.afii57671 = 0x05d7;
  t.afii57672 = 0x05d8;
  t.afii57673 = 0x05d9;
  t.afii57674 = 0x05da;
  t.afii57675 = 0x05db;
  t.afii57676 = 0x05dc;
  t.afii57677 = 0x05dd;
  t.afii57678 = 0x05de;
  t.afii57679 = 0x05df;
  t.afii57680 = 0x05e0;
  t.afii57681 = 0x05e1;
  t.afii57682 = 0x05e2;
  t.afii57683 = 0x05e3;
  t.afii57684 = 0x05e4;
  t.afii57685 = 0x05e5;
  t.afii57686 = 0x05e6;
  t.afii57687 = 0x05e7;
  t.afii57688 = 0x05e8;
  t.afii57689 = 0x05e9;
  t.afii57690 = 0x05ea;
  t.afii57694 = 0xfb2a;
  t.afii57695 = 0xfb2b;
  t.afii57700 = 0xfb4b;
  t.afii57705 = 0xfb1f;
  t.afii57716 = 0x05f0;
  t.afii57717 = 0x05f1;
  t.afii57718 = 0x05f2;
  t.afii57723 = 0xfb35;
  t.afii57793 = 0x05b4;
  t.afii57794 = 0x05b5;
  t.afii57795 = 0x05b6;
  t.afii57796 = 0x05bb;
  t.afii57797 = 0x05b8;
  t.afii57798 = 0x05b7;
  t.afii57799 = 0x05b0;
  t.afii57800 = 0x05b2;
  t.afii57801 = 0x05b1;
  t.afii57802 = 0x05b3;
  t.afii57803 = 0x05c2;
  t.afii57804 = 0x05c1;
  t.afii57806 = 0x05b9;
  t.afii57807 = 0x05bc;
  t.afii57839 = 0x05bd;
  t.afii57841 = 0x05bf;
  t.afii57842 = 0x05c0;
  t.afii57929 = 0x02bc;
  t.afii61248 = 0x2105;
  t.afii61289 = 0x2113;
  t.afii61352 = 0x2116;
  t.afii61573 = 0x202c;
  t.afii61574 = 0x202d;
  t.afii61575 = 0x202e;
  t.afii61664 = 0x200c;
  t.afii63167 = 0x066d;
  t.afii64937 = 0x02bd;
  t.agrave = 0x00e0;
  t.agujarati = 0x0a85;
  t.agurmukhi = 0x0a05;
  t.ahiragana = 0x3042;
  t.ahookabove = 0x1ea3;
  t.aibengali = 0x0990;
  t.aibopomofo = 0x311e;
  t.aideva = 0x0910;
  t.aiecyrillic = 0x04d5;
  t.aigujarati = 0x0a90;
  t.aigurmukhi = 0x0a10;
  t.aimatragurmukhi = 0x0a48;
  t.ainarabic = 0x0639;
  t.ainfinalarabic = 0xfeca;
  t.aininitialarabic = 0xfecb;
  t.ainmedialarabic = 0xfecc;
  t.ainvertedbreve = 0x0203;
  t.aivowelsignbengali = 0x09c8;
  t.aivowelsigndeva = 0x0948;
  t.aivowelsigngujarati = 0x0ac8;
  t.akatakana = 0x30a2;
  t.akatakanahalfwidth = 0xff71;
  t.akorean = 0x314f;
  t.alef = 0x05d0;
  t.alefarabic = 0x0627;
  t.alefdageshhebrew = 0xfb30;
  t.aleffinalarabic = 0xfe8e;
  t.alefhamzaabovearabic = 0x0623;
  t.alefhamzaabovefinalarabic = 0xfe84;
  t.alefhamzabelowarabic = 0x0625;
  t.alefhamzabelowfinalarabic = 0xfe88;
  t.alefhebrew = 0x05d0;
  t.aleflamedhebrew = 0xfb4f;
  t.alefmaddaabovearabic = 0x0622;
  t.alefmaddaabovefinalarabic = 0xfe82;
  t.alefmaksuraarabic = 0x0649;
  t.alefmaksurafinalarabic = 0xfef0;
  t.alefmaksurainitialarabic = 0xfef3;
  t.alefmaksuramedialarabic = 0xfef4;
  t.alefpatahhebrew = 0xfb2e;
  t.alefqamatshebrew = 0xfb2f;
  t.aleph = 0x2135;
  t.allequal = 0x224c;
  t.alpha = 0x03b1;
  t.alphatonos = 0x03ac;
  t.amacron = 0x0101;
  t.amonospace = 0xff41;
  t.ampersand = 0x0026;
  t.ampersandmonospace = 0xff06;
  t.ampersandsmall = 0xf726;
  t.amsquare = 0x33c2;
  t.anbopomofo = 0x3122;
  t.angbopomofo = 0x3124;
  t.angbracketleft = 0x3008; // Glyph is missing from Adobe's original list.
  t.angbracketright = 0x3009; // Glyph is missing from Adobe's original list.
  t.angkhankhuthai = 0x0e5a;
  t.angle = 0x2220;
  t.anglebracketleft = 0x3008;
  t.anglebracketleftvertical = 0xfe3f;
  t.anglebracketright = 0x3009;
  t.anglebracketrightvertical = 0xfe40;
  t.angleleft = 0x2329;
  t.angleright = 0x232a;
  t.angstrom = 0x212b;
  t.anoteleia = 0x0387;
  t.anudattadeva = 0x0952;
  t.anusvarabengali = 0x0982;
  t.anusvaradeva = 0x0902;
  t.anusvaragujarati = 0x0a82;
  t.aogonek = 0x0105;
  t.apaatosquare = 0x3300;
  t.aparen = 0x249c;
  t.apostrophearmenian = 0x055a;
  t.apostrophemod = 0x02bc;
  t.apple = 0xf8ff;
  t.approaches = 0x2250;
  t.approxequal = 0x2248;
  t.approxequalorimage = 0x2252;
  t.approximatelyequal = 0x2245;
  t.araeaekorean = 0x318e;
  t.araeakorean = 0x318d;
  t.arc = 0x2312;
  t.arighthalfring = 0x1e9a;
  t.aring = 0x00e5;
  t.aringacute = 0x01fb;
  t.aringbelow = 0x1e01;
  t.arrowboth = 0x2194;
  t.arrowdashdown = 0x21e3;
  t.arrowdashleft = 0x21e0;
  t.arrowdashright = 0x21e2;
  t.arrowdashup = 0x21e1;
  t.arrowdblboth = 0x21d4;
  t.arrowdbldown = 0x21d3;
  t.arrowdblleft = 0x21d0;
  t.arrowdblright = 0x21d2;
  t.arrowdblup = 0x21d1;
  t.arrowdown = 0x2193;
  t.arrowdownleft = 0x2199;
  t.arrowdownright = 0x2198;
  t.arrowdownwhite = 0x21e9;
  t.arrowheaddownmod = 0x02c5;
  t.arrowheadleftmod = 0x02c2;
  t.arrowheadrightmod = 0x02c3;
  t.arrowheadupmod = 0x02c4;
  t.arrowhorizex = 0xf8e7;
  t.arrowleft = 0x2190;
  t.arrowleftdbl = 0x21d0;
  t.arrowleftdblstroke = 0x21cd;
  t.arrowleftoverright = 0x21c6;
  t.arrowleftwhite = 0x21e6;
  t.arrowright = 0x2192;
  t.arrowrightdblstroke = 0x21cf;
  t.arrowrightheavy = 0x279e;
  t.arrowrightoverleft = 0x21c4;
  t.arrowrightwhite = 0x21e8;
  t.arrowtableft = 0x21e4;
  t.arrowtabright = 0x21e5;
  t.arrowup = 0x2191;
  t.arrowupdn = 0x2195;
  t.arrowupdnbse = 0x21a8;
  t.arrowupdownbase = 0x21a8;
  t.arrowupleft = 0x2196;
  t.arrowupleftofdown = 0x21c5;
  t.arrowupright = 0x2197;
  t.arrowupwhite = 0x21e7;
  t.arrowvertex = 0xf8e6;
  t.asciicircum = 0x005e;
  t.asciicircummonospace = 0xff3e;
  t.asciitilde = 0x007e;
  t.asciitildemonospace = 0xff5e;
  t.ascript = 0x0251;
  t.ascriptturned = 0x0252;
  t.asmallhiragana = 0x3041;
  t.asmallkatakana = 0x30a1;
  t.asmallkatakanahalfwidth = 0xff67;
  t.asterisk = 0x002a;
  t.asteriskaltonearabic = 0x066d;
  t.asteriskarabic = 0x066d;
  t.asteriskmath = 0x2217;
  t.asteriskmonospace = 0xff0a;
  t.asterisksmall = 0xfe61;
  t.asterism = 0x2042;
  t.asuperior = 0xf6e9;
  t.asymptoticallyequal = 0x2243;
  t.at = 0x0040;
  t.atilde = 0x00e3;
  t.atmonospace = 0xff20;
  t.atsmall = 0xfe6b;
  t.aturned = 0x0250;
  t.aubengali = 0x0994;
  t.aubopomofo = 0x3120;
  t.audeva = 0x0914;
  t.augujarati = 0x0a94;
  t.augurmukhi = 0x0a14;
  t.aulengthmarkbengali = 0x09d7;
  t.aumatragurmukhi = 0x0a4c;
  t.auvowelsignbengali = 0x09cc;
  t.auvowelsigndeva = 0x094c;
  t.auvowelsigngujarati = 0x0acc;
  t.avagrahadeva = 0x093d;
  t.aybarmenian = 0x0561;
  t.ayin = 0x05e2;
  t.ayinaltonehebrew = 0xfb20;
  t.ayinhebrew = 0x05e2;
  t.b = 0x0062;
  t.babengali = 0x09ac;
  t.backslash = 0x005c;
  t.backslashmonospace = 0xff3c;
  t.badeva = 0x092c;
  t.bagujarati = 0x0aac;
  t.bagurmukhi = 0x0a2c;
  t.bahiragana = 0x3070;
  t.bahtthai = 0x0e3f;
  t.bakatakana = 0x30d0;
  t.bar = 0x007c;
  t.barmonospace = 0xff5c;
  t.bbopomofo = 0x3105;
  t.bcircle = 0x24d1;
  t.bdotaccent = 0x1e03;
  t.bdotbelow = 0x1e05;
  t.beamedsixteenthnotes = 0x266c;
  t.because = 0x2235;
  t.becyrillic = 0x0431;
  t.beharabic = 0x0628;
  t.behfinalarabic = 0xfe90;
  t.behinitialarabic = 0xfe91;
  t.behiragana = 0x3079;
  t.behmedialarabic = 0xfe92;
  t.behmeeminitialarabic = 0xfc9f;
  t.behmeemisolatedarabic = 0xfc08;
  t.behnoonfinalarabic = 0xfc6d;
  t.bekatakana = 0x30d9;
  t.benarmenian = 0x0562;
  t.bet = 0x05d1;
  t.beta = 0x03b2;
  t.betasymbolgreek = 0x03d0;
  t.betdagesh = 0xfb31;
  t.betdageshhebrew = 0xfb31;
  t.bethebrew = 0x05d1;
  t.betrafehebrew = 0xfb4c;
  t.bhabengali = 0x09ad;
  t.bhadeva = 0x092d;
  t.bhagujarati = 0x0aad;
  t.bhagurmukhi = 0x0a2d;
  t.bhook = 0x0253;
  t.bihiragana = 0x3073;
  t.bikatakana = 0x30d3;
  t.bilabialclick = 0x0298;
  t.bindigurmukhi = 0x0a02;
  t.birusquare = 0x3331;
  t.blackcircle = 0x25cf;
  t.blackdiamond = 0x25c6;
  t.blackdownpointingtriangle = 0x25bc;
  t.blackleftpointingpointer = 0x25c4;
  t.blackleftpointingtriangle = 0x25c0;
  t.blacklenticularbracketleft = 0x3010;
  t.blacklenticularbracketleftvertical = 0xfe3b;
  t.blacklenticularbracketright = 0x3011;
  t.blacklenticularbracketrightvertical = 0xfe3c;
  t.blacklowerlefttriangle = 0x25e3;
  t.blacklowerrighttriangle = 0x25e2;
  t.blackrectangle = 0x25ac;
  t.blackrightpointingpointer = 0x25ba;
  t.blackrightpointingtriangle = 0x25b6;
  t.blacksmallsquare = 0x25aa;
  t.blacksmilingface = 0x263b;
  t.blacksquare = 0x25a0;
  t.blackstar = 0x2605;
  t.blackupperlefttriangle = 0x25e4;
  t.blackupperrighttriangle = 0x25e5;
  t.blackuppointingsmalltriangle = 0x25b4;
  t.blackuppointingtriangle = 0x25b2;
  t.blank = 0x2423;
  t.blinebelow = 0x1e07;
  t.block = 0x2588;
  t.bmonospace = 0xff42;
  t.bobaimaithai = 0x0e1a;
  t.bohiragana = 0x307c;
  t.bokatakana = 0x30dc;
  t.bparen = 0x249d;
  t.bqsquare = 0x33c3;
  t.braceex = 0xf8f4;
  t.braceleft = 0x007b;
  t.braceleftbt = 0xf8f3;
  t.braceleftmid = 0xf8f2;
  t.braceleftmonospace = 0xff5b;
  t.braceleftsmall = 0xfe5b;
  t.bracelefttp = 0xf8f1;
  t.braceleftvertical = 0xfe37;
  t.braceright = 0x007d;
  t.bracerightbt = 0xf8fe;
  t.bracerightmid = 0xf8fd;
  t.bracerightmonospace = 0xff5d;
  t.bracerightsmall = 0xfe5c;
  t.bracerighttp = 0xf8fc;
  t.bracerightvertical = 0xfe38;
  t.bracketleft = 0x005b;
  t.bracketleftbt = 0xf8f0;
  t.bracketleftex = 0xf8ef;
  t.bracketleftmonospace = 0xff3b;
  t.bracketlefttp = 0xf8ee;
  t.bracketright = 0x005d;
  t.bracketrightbt = 0xf8fb;
  t.bracketrightex = 0xf8fa;
  t.bracketrightmonospace = 0xff3d;
  t.bracketrighttp = 0xf8f9;
  t.breve = 0x02d8;
  t.brevebelowcmb = 0x032e;
  t.brevecmb = 0x0306;
  t.breveinvertedbelowcmb = 0x032f;
  t.breveinvertedcmb = 0x0311;
  t.breveinverteddoublecmb = 0x0361;
  t.bridgebelowcmb = 0x032a;
  t.bridgeinvertedbelowcmb = 0x033a;
  t.brokenbar = 0x00a6;
  t.bstroke = 0x0180;
  t.bsuperior = 0xf6ea;
  t.btopbar = 0x0183;
  t.buhiragana = 0x3076;
  t.bukatakana = 0x30d6;
  t.bullet = 0x2022;
  t.bulletinverse = 0x25d8;
  t.bulletoperator = 0x2219;
  t.bullseye = 0x25ce;
  t.c = 0x0063;
  t.caarmenian = 0x056e;
  t.cabengali = 0x099a;
  t.cacute = 0x0107;
  t.cadeva = 0x091a;
  t.cagujarati = 0x0a9a;
  t.cagurmukhi = 0x0a1a;
  t.calsquare = 0x3388;
  t.candrabindubengali = 0x0981;
  t.candrabinducmb = 0x0310;
  t.candrabindudeva = 0x0901;
  t.candrabindugujarati = 0x0a81;
  t.capslock = 0x21ea;
  t.careof = 0x2105;
  t.caron = 0x02c7;
  t.caronbelowcmb = 0x032c;
  t.caroncmb = 0x030c;
  t.carriagereturn = 0x21b5;
  t.cbopomofo = 0x3118;
  t.ccaron = 0x010d;
  t.ccedilla = 0x00e7;
  t.ccedillaacute = 0x1e09;
  t.ccircle = 0x24d2;
  t.ccircumflex = 0x0109;
  t.ccurl = 0x0255;
  t.cdot = 0x010b;
  t.cdotaccent = 0x010b;
  t.cdsquare = 0x33c5;
  t.cedilla = 0x00b8;
  t.cedillacmb = 0x0327;
  t.cent = 0x00a2;
  t.centigrade = 0x2103;
  t.centinferior = 0xf6df;
  t.centmonospace = 0xffe0;
  t.centoldstyle = 0xf7a2;
  t.centsuperior = 0xf6e0;
  t.chaarmenian = 0x0579;
  t.chabengali = 0x099b;
  t.chadeva = 0x091b;
  t.chagujarati = 0x0a9b;
  t.chagurmukhi = 0x0a1b;
  t.chbopomofo = 0x3114;
  t.cheabkhasiancyrillic = 0x04bd;
  t.checkmark = 0x2713;
  t.checyrillic = 0x0447;
  t.chedescenderabkhasiancyrillic = 0x04bf;
  t.chedescendercyrillic = 0x04b7;
  t.chedieresiscyrillic = 0x04f5;
  t.cheharmenian = 0x0573;
  t.chekhakassiancyrillic = 0x04cc;
  t.cheverticalstrokecyrillic = 0x04b9;
  t.chi = 0x03c7;
  t.chieuchacirclekorean = 0x3277;
  t.chieuchaparenkorean = 0x3217;
  t.chieuchcirclekorean = 0x3269;
  t.chieuchkorean = 0x314a;
  t.chieuchparenkorean = 0x3209;
  t.chochangthai = 0x0e0a;
  t.chochanthai = 0x0e08;
  t.chochingthai = 0x0e09;
  t.chochoethai = 0x0e0c;
  t.chook = 0x0188;
  t.cieucacirclekorean = 0x3276;
  t.cieucaparenkorean = 0x3216;
  t.cieuccirclekorean = 0x3268;
  t.cieuckorean = 0x3148;
  t.cieucparenkorean = 0x3208;
  t.cieucuparenkorean = 0x321c;
  t.circle = 0x25cb;
  t.circlecopyrt = 0x00a9; // Glyph is missing from Adobe's original list.
  t.circlemultiply = 0x2297;
  t.circleot = 0x2299;
  t.circleplus = 0x2295;
  t.circlepostalmark = 0x3036;
  t.circlewithlefthalfblack = 0x25d0;
  t.circlewithrighthalfblack = 0x25d1;
  t.circumflex = 0x02c6;
  t.circumflexbelowcmb = 0x032d;
  t.circumflexcmb = 0x0302;
  t.clear = 0x2327;
  t.clickalveolar = 0x01c2;
  t.clickdental = 0x01c0;
  t.clicklateral = 0x01c1;
  t.clickretroflex = 0x01c3;
  t.club = 0x2663;
  t.clubsuitblack = 0x2663;
  t.clubsuitwhite = 0x2667;
  t.cmcubedsquare = 0x33a4;
  t.cmonospace = 0xff43;
  t.cmsquaredsquare = 0x33a0;
  t.coarmenian = 0x0581;
  t.colon = 0x003a;
  t.colonmonetary = 0x20a1;
  t.colonmonospace = 0xff1a;
  t.colonsign = 0x20a1;
  t.colonsmall = 0xfe55;
  t.colontriangularhalfmod = 0x02d1;
  t.colontriangularmod = 0x02d0;
  t.comma = 0x002c;
  t.commaabovecmb = 0x0313;
  t.commaaboverightcmb = 0x0315;
  t.commaaccent = 0xf6c3;
  t.commaarabic = 0x060c;
  t.commaarmenian = 0x055d;
  t.commainferior = 0xf6e1;
  t.commamonospace = 0xff0c;
  t.commareversedabovecmb = 0x0314;
  t.commareversedmod = 0x02bd;
  t.commasmall = 0xfe50;
  t.commasuperior = 0xf6e2;
  t.commaturnedabovecmb = 0x0312;
  t.commaturnedmod = 0x02bb;
  t.compass = 0x263c;
  t.congruent = 0x2245;
  t.contourintegral = 0x222e;
  t.control = 0x2303;
  t.controlACK = 0x0006;
  t.controlBEL = 0x0007;
  t.controlBS = 0x0008;
  t.controlCAN = 0x0018;
  t.controlCR = 0x000d;
  t.controlDC1 = 0x0011;
  t.controlDC2 = 0x0012;
  t.controlDC3 = 0x0013;
  t.controlDC4 = 0x0014;
  t.controlDEL = 0x007f;
  t.controlDLE = 0x0010;
  t.controlEM = 0x0019;
  t.controlENQ = 0x0005;
  t.controlEOT = 0x0004;
  t.controlESC = 0x001b;
  t.controlETB = 0x0017;
  t.controlETX = 0x0003;
  t.controlFF = 0x000c;
  t.controlFS = 0x001c;
  t.controlGS = 0x001d;
  t.controlHT = 0x0009;
  t.controlLF = 0x000a;
  t.controlNAK = 0x0015;
  t.controlNULL = 0x0000; // Glyph is missing from Adobe's original list.
  t.controlRS = 0x001e;
  t.controlSI = 0x000f;
  t.controlSO = 0x000e;
  t.controlSOT = 0x0002;
  t.controlSTX = 0x0001;
  t.controlSUB = 0x001a;
  t.controlSYN = 0x0016;
  t.controlUS = 0x001f;
  t.controlVT = 0x000b;
  t.copyright = 0x00a9;
  t.copyrightsans = 0xf8e9;
  t.copyrightserif = 0xf6d9;
  t.cornerbracketleft = 0x300c;
  t.cornerbracketlefthalfwidth = 0xff62;
  t.cornerbracketleftvertical = 0xfe41;
  t.cornerbracketright = 0x300d;
  t.cornerbracketrighthalfwidth = 0xff63;
  t.cornerbracketrightvertical = 0xfe42;
  t.corporationsquare = 0x337f;
  t.cosquare = 0x33c7;
  t.coverkgsquare = 0x33c6;
  t.cparen = 0x249e;
  t.cruzeiro = 0x20a2;
  t.cstretched = 0x0297;
  t.curlyand = 0x22cf;
  t.curlyor = 0x22ce;
  t.currency = 0x00a4;
  t.cyrBreve = 0xf6d1;
  t.cyrFlex = 0xf6d2;
  t.cyrbreve = 0xf6d4;
  t.cyrflex = 0xf6d5;
  t.d = 0x0064;
  t.daarmenian = 0x0564;
  t.dabengali = 0x09a6;
  t.dadarabic = 0x0636;
  t.dadeva = 0x0926;
  t.dadfinalarabic = 0xfebe;
  t.dadinitialarabic = 0xfebf;
  t.dadmedialarabic = 0xfec0;
  t.dagesh = 0x05bc;
  t.dageshhebrew = 0x05bc;
  t.dagger = 0x2020;
  t.daggerdbl = 0x2021;
  t.dagujarati = 0x0aa6;
  t.dagurmukhi = 0x0a26;
  t.dahiragana = 0x3060;
  t.dakatakana = 0x30c0;
  t.dalarabic = 0x062f;
  t.dalet = 0x05d3;
  t.daletdagesh = 0xfb33;
  t.daletdageshhebrew = 0xfb33;
  t.dalethebrew = 0x05d3;
  t.dalfinalarabic = 0xfeaa;
  t.dammaarabic = 0x064f;
  t.dammalowarabic = 0x064f;
  t.dammatanaltonearabic = 0x064c;
  t.dammatanarabic = 0x064c;
  t.danda = 0x0964;
  t.dargahebrew = 0x05a7;
  t.dargalefthebrew = 0x05a7;
  t.dasiapneumatacyrilliccmb = 0x0485;
  t.dblGrave = 0xf6d3;
  t.dblanglebracketleft = 0x300a;
  t.dblanglebracketleftvertical = 0xfe3d;
  t.dblanglebracketright = 0x300b;
  t.dblanglebracketrightvertical = 0xfe3e;
  t.dblarchinvertedbelowcmb = 0x032b;
  t.dblarrowleft = 0x21d4;
  t.dblarrowright = 0x21d2;
  t.dbldanda = 0x0965;
  t.dblgrave = 0xf6d6;
  t.dblgravecmb = 0x030f;
  t.dblintegral = 0x222c;
  t.dbllowline = 0x2017;
  t.dbllowlinecmb = 0x0333;
  t.dbloverlinecmb = 0x033f;
  t.dblprimemod = 0x02ba;
  t.dblverticalbar = 0x2016;
  t.dblverticallineabovecmb = 0x030e;
  t.dbopomofo = 0x3109;
  t.dbsquare = 0x33c8;
  t.dcaron = 0x010f;
  t.dcedilla = 0x1e11;
  t.dcircle = 0x24d3;
  t.dcircumflexbelow = 0x1e13;
  t.dcroat = 0x0111;
  t.ddabengali = 0x09a1;
  t.ddadeva = 0x0921;
  t.ddagujarati = 0x0aa1;
  t.ddagurmukhi = 0x0a21;
  t.ddalarabic = 0x0688;
  t.ddalfinalarabic = 0xfb89;
  t.dddhadeva = 0x095c;
  t.ddhabengali = 0x09a2;
  t.ddhadeva = 0x0922;
  t.ddhagujarati = 0x0aa2;
  t.ddhagurmukhi = 0x0a22;
  t.ddotaccent = 0x1e0b;
  t.ddotbelow = 0x1e0d;
  t.decimalseparatorarabic = 0x066b;
  t.decimalseparatorpersian = 0x066b;
  t.decyrillic = 0x0434;
  t.degree = 0x00b0;
  t.dehihebrew = 0x05ad;
  t.dehiragana = 0x3067;
  t.deicoptic = 0x03ef;
  t.dekatakana = 0x30c7;
  t.deleteleft = 0x232b;
  t.deleteright = 0x2326;
  t.delta = 0x03b4;
  t.deltaturned = 0x018d;
  t.denominatorminusonenumeratorbengali = 0x09f8;
  t.dezh = 0x02a4;
  t.dhabengali = 0x09a7;
  t.dhadeva = 0x0927;
  t.dhagujarati = 0x0aa7;
  t.dhagurmukhi = 0x0a27;
  t.dhook = 0x0257;
  t.dialytikatonos = 0x0385;
  t.dialytikatonoscmb = 0x0344;
  t.diamond = 0x2666;
  t.diamondsuitwhite = 0x2662;
  t.dieresis = 0x00a8;
  t.dieresisacute = 0xf6d7;
  t.dieresisbelowcmb = 0x0324;
  t.dieresiscmb = 0x0308;
  t.dieresisgrave = 0xf6d8;
  t.dieresistonos = 0x0385;
  t.dihiragana = 0x3062;
  t.dikatakana = 0x30c2;
  t.dittomark = 0x3003;
  t.divide = 0x00f7;
  t.divides = 0x2223;
  t.divisionslash = 0x2215;
  t.djecyrillic = 0x0452;
  t.dkshade = 0x2593;
  t.dlinebelow = 0x1e0f;
  t.dlsquare = 0x3397;
  t.dmacron = 0x0111;
  t.dmonospace = 0xff44;
  t.dnblock = 0x2584;
  t.dochadathai = 0x0e0e;
  t.dodekthai = 0x0e14;
  t.dohiragana = 0x3069;
  t.dokatakana = 0x30c9;
  t.dollar = 0x0024;
  t.dollarinferior = 0xf6e3;
  t.dollarmonospace = 0xff04;
  t.dollaroldstyle = 0xf724;
  t.dollarsmall = 0xfe69;
  t.dollarsuperior = 0xf6e4;
  t.dong = 0x20ab;
  t.dorusquare = 0x3326;
  t.dotaccent = 0x02d9;
  t.dotaccentcmb = 0x0307;
  t.dotbelowcmb = 0x0323;
  t.dotbelowcomb = 0x0323;
  t.dotkatakana = 0x30fb;
  t.dotlessi = 0x0131;
  t.dotlessj = 0xf6be;
  t.dotlessjstrokehook = 0x0284;
  t.dotmath = 0x22c5;
  t.dottedcircle = 0x25cc;
  t.doubleyodpatah = 0xfb1f;
  t.doubleyodpatahhebrew = 0xfb1f;
  t.downtackbelowcmb = 0x031e;
  t.downtackmod = 0x02d5;
  t.dparen = 0x249f;
  t.dsuperior = 0xf6eb;
  t.dtail = 0x0256;
  t.dtopbar = 0x018c;
  t.duhiragana = 0x3065;
  t.dukatakana = 0x30c5;
  t.dz = 0x01f3;
  t.dzaltone = 0x02a3;
  t.dzcaron = 0x01c6;
  t.dzcurl = 0x02a5;
  t.dzeabkhasiancyrillic = 0x04e1;
  t.dzecyrillic = 0x0455;
  t.dzhecyrillic = 0x045f;
  t.e = 0x0065;
  t.eacute = 0x00e9;
  t.earth = 0x2641;
  t.ebengali = 0x098f;
  t.ebopomofo = 0x311c;
  t.ebreve = 0x0115;
  t.ecandradeva = 0x090d;
  t.ecandragujarati = 0x0a8d;
  t.ecandravowelsigndeva = 0x0945;
  t.ecandravowelsigngujarati = 0x0ac5;
  t.ecaron = 0x011b;
  t.ecedillabreve = 0x1e1d;
  t.echarmenian = 0x0565;
  t.echyiwnarmenian = 0x0587;
  t.ecircle = 0x24d4;
  t.ecircumflex = 0x00ea;
  t.ecircumflexacute = 0x1ebf;
  t.ecircumflexbelow = 0x1e19;
  t.ecircumflexdotbelow = 0x1ec7;
  t.ecircumflexgrave = 0x1ec1;
  t.ecircumflexhookabove = 0x1ec3;
  t.ecircumflextilde = 0x1ec5;
  t.ecyrillic = 0x0454;
  t.edblgrave = 0x0205;
  t.edeva = 0x090f;
  t.edieresis = 0x00eb;
  t.edot = 0x0117;
  t.edotaccent = 0x0117;
  t.edotbelow = 0x1eb9;
  t.eegurmukhi = 0x0a0f;
  t.eematragurmukhi = 0x0a47;
  t.efcyrillic = 0x0444;
  t.egrave = 0x00e8;
  t.egujarati = 0x0a8f;
  t.eharmenian = 0x0567;
  t.ehbopomofo = 0x311d;
  t.ehiragana = 0x3048;
  t.ehookabove = 0x1ebb;
  t.eibopomofo = 0x311f;
  t.eight = 0x0038;
  t.eightarabic = 0x0668;
  t.eightbengali = 0x09ee;
  t.eightcircle = 0x2467;
  t.eightcircleinversesansserif = 0x2791;
  t.eightdeva = 0x096e;
  t.eighteencircle = 0x2471;
  t.eighteenparen = 0x2485;
  t.eighteenperiod = 0x2499;
  t.eightgujarati = 0x0aee;
  t.eightgurmukhi = 0x0a6e;
  t.eighthackarabic = 0x0668;
  t.eighthangzhou = 0x3028;
  t.eighthnotebeamed = 0x266b;
  t.eightideographicparen = 0x3227;
  t.eightinferior = 0x2088;
  t.eightmonospace = 0xff18;
  t.eightoldstyle = 0xf738;
  t.eightparen = 0x247b;
  t.eightperiod = 0x248f;
  t.eightpersian = 0x06f8;
  t.eightroman = 0x2177;
  t.eightsuperior = 0x2078;
  t.eightthai = 0x0e58;
  t.einvertedbreve = 0x0207;
  t.eiotifiedcyrillic = 0x0465;
  t.ekatakana = 0x30a8;
  t.ekatakanahalfwidth = 0xff74;
  t.ekonkargurmukhi = 0x0a74;
  t.ekorean = 0x3154;
  t.elcyrillic = 0x043b;
  t.element = 0x2208;
  t.elevencircle = 0x246a;
  t.elevenparen = 0x247e;
  t.elevenperiod = 0x2492;
  t.elevenroman = 0x217a;
  t.ellipsis = 0x2026;
  t.ellipsisvertical = 0x22ee;
  t.emacron = 0x0113;
  t.emacronacute = 0x1e17;
  t.emacrongrave = 0x1e15;
  t.emcyrillic = 0x043c;
  t.emdash = 0x2014;
  t.emdashvertical = 0xfe31;
  t.emonospace = 0xff45;
  t.emphasismarkarmenian = 0x055b;
  t.emptyset = 0x2205;
  t.enbopomofo = 0x3123;
  t.encyrillic = 0x043d;
  t.endash = 0x2013;
  t.endashvertical = 0xfe32;
  t.endescendercyrillic = 0x04a3;
  t.eng = 0x014b;
  t.engbopomofo = 0x3125;
  t.enghecyrillic = 0x04a5;
  t.enhookcyrillic = 0x04c8;
  t.enspace = 0x2002;
  t.eogonek = 0x0119;
  t.eokorean = 0x3153;
  t.eopen = 0x025b;
  t.eopenclosed = 0x029a;
  t.eopenreversed = 0x025c;
  t.eopenreversedclosed = 0x025e;
  t.eopenreversedhook = 0x025d;
  t.eparen = 0x24a0;
  t.epsilon = 0x03b5;
  t.epsilontonos = 0x03ad;
  t.equal = 0x003d;
  t.equalmonospace = 0xff1d;
  t.equalsmall = 0xfe66;
  t.equalsuperior = 0x207c;
  t.equivalence = 0x2261;
  t.erbopomofo = 0x3126;
  t.ercyrillic = 0x0440;
  t.ereversed = 0x0258;
  t.ereversedcyrillic = 0x044d;
  t.escyrillic = 0x0441;
  t.esdescendercyrillic = 0x04ab;
  t.esh = 0x0283;
  t.eshcurl = 0x0286;
  t.eshortdeva = 0x090e;
  t.eshortvowelsigndeva = 0x0946;
  t.eshreversedloop = 0x01aa;
  t.eshsquatreversed = 0x0285;
  t.esmallhiragana = 0x3047;
  t.esmallkatakana = 0x30a7;
  t.esmallkatakanahalfwidth = 0xff6a;
  t.estimated = 0x212e;
  t.esuperior = 0xf6ec;
  t.eta = 0x03b7;
  t.etarmenian = 0x0568;
  t.etatonos = 0x03ae;
  t.eth = 0x00f0;
  t.etilde = 0x1ebd;
  t.etildebelow = 0x1e1b;
  t.etnahtafoukhhebrew = 0x0591;
  t.etnahtafoukhlefthebrew = 0x0591;
  t.etnahtahebrew = 0x0591;
  t.etnahtalefthebrew = 0x0591;
  t.eturned = 0x01dd;
  t.eukorean = 0x3161;
  t.euro = 0x20ac;
  t.evowelsignbengali = 0x09c7;
  t.evowelsigndeva = 0x0947;
  t.evowelsigngujarati = 0x0ac7;
  t.exclam = 0x0021;
  t.exclamarmenian = 0x055c;
  t.exclamdbl = 0x203c;
  t.exclamdown = 0x00a1;
  t.exclamdownsmall = 0xf7a1;
  t.exclammonospace = 0xff01;
  t.exclamsmall = 0xf721;
  t.existential = 0x2203;
  t.ezh = 0x0292;
  t.ezhcaron = 0x01ef;
  t.ezhcurl = 0x0293;
  t.ezhreversed = 0x01b9;
  t.ezhtail = 0x01ba;
  t.f = 0x0066;
  t.fadeva = 0x095e;
  t.fagurmukhi = 0x0a5e;
  t.fahrenheit = 0x2109;
  t.fathaarabic = 0x064e;
  t.fathalowarabic = 0x064e;
  t.fathatanarabic = 0x064b;
  t.fbopomofo = 0x3108;
  t.fcircle = 0x24d5;
  t.fdotaccent = 0x1e1f;
  t.feharabic = 0x0641;
  t.feharmenian = 0x0586;
  t.fehfinalarabic = 0xfed2;
  t.fehinitialarabic = 0xfed3;
  t.fehmedialarabic = 0xfed4;
  t.feicoptic = 0x03e5;
  t.female = 0x2640;
  t.ff = 0xfb00;
  t.f_f = 0xfb00;
  t.ffi = 0xfb03;
  t.f_f_i = 0xfb03;
  t.ffl = 0xfb04;
  t.f_f_l = 0xfb04;
  t.fi = 0xfb01;
  t.f_i = 0xfb01;
  t.fifteencircle = 0x246e;
  t.fifteenparen = 0x2482;
  t.fifteenperiod = 0x2496;
  t.figuredash = 0x2012;
  t.filledbox = 0x25a0;
  t.filledrect = 0x25ac;
  t.finalkaf = 0x05da;
  t.finalkafdagesh = 0xfb3a;
  t.finalkafdageshhebrew = 0xfb3a;
  t.finalkafhebrew = 0x05da;
  t.finalmem = 0x05dd;
  t.finalmemhebrew = 0x05dd;
  t.finalnun = 0x05df;
  t.finalnunhebrew = 0x05df;
  t.finalpe = 0x05e3;
  t.finalpehebrew = 0x05e3;
  t.finaltsadi = 0x05e5;
  t.finaltsadihebrew = 0x05e5;
  t.firsttonechinese = 0x02c9;
  t.fisheye = 0x25c9;
  t.fitacyrillic = 0x0473;
  t.five = 0x0035;
  t.fivearabic = 0x0665;
  t.fivebengali = 0x09eb;
  t.fivecircle = 0x2464;
  t.fivecircleinversesansserif = 0x278e;
  t.fivedeva = 0x096b;
  t.fiveeighths = 0x215d;
  t.fivegujarati = 0x0aeb;
  t.fivegurmukhi = 0x0a6b;
  t.fivehackarabic = 0x0665;
  t.fivehangzhou = 0x3025;
  t.fiveideographicparen = 0x3224;
  t.fiveinferior = 0x2085;
  t.fivemonospace = 0xff15;
  t.fiveoldstyle = 0xf735;
  t.fiveparen = 0x2478;
  t.fiveperiod = 0x248c;
  t.fivepersian = 0x06f5;
  t.fiveroman = 0x2174;
  t.fivesuperior = 0x2075;
  t.fivethai = 0x0e55;
  t.fl = 0xfb02;
  t.f_l = 0xfb02;
  t.florin = 0x0192;
  t.fmonospace = 0xff46;
  t.fmsquare = 0x3399;
  t.fofanthai = 0x0e1f;
  t.fofathai = 0x0e1d;
  t.fongmanthai = 0x0e4f;
  t.forall = 0x2200;
  t.four = 0x0034;
  t.fourarabic = 0x0664;
  t.fourbengali = 0x09ea;
  t.fourcircle = 0x2463;
  t.fourcircleinversesansserif = 0x278d;
  t.fourdeva = 0x096a;
  t.fourgujarati = 0x0aea;
  t.fourgurmukhi = 0x0a6a;
  t.fourhackarabic = 0x0664;
  t.fourhangzhou = 0x3024;
  t.fourideographicparen = 0x3223;
  t.fourinferior = 0x2084;
  t.fourmonospace = 0xff14;
  t.fournumeratorbengali = 0x09f7;
  t.fouroldstyle = 0xf734;
  t.fourparen = 0x2477;
  t.fourperiod = 0x248b;
  t.fourpersian = 0x06f4;
  t.fourroman = 0x2173;
  t.foursuperior = 0x2074;
  t.fourteencircle = 0x246d;
  t.fourteenparen = 0x2481;
  t.fourteenperiod = 0x2495;
  t.fourthai = 0x0e54;
  t.fourthtonechinese = 0x02cb;
  t.fparen = 0x24a1;
  t.fraction = 0x2044;
  t.franc = 0x20a3;
  t.g = 0x0067;
  t.gabengali = 0x0997;
  t.gacute = 0x01f5;
  t.gadeva = 0x0917;
  t.gafarabic = 0x06af;
  t.gaffinalarabic = 0xfb93;
  t.gafinitialarabic = 0xfb94;
  t.gafmedialarabic = 0xfb95;
  t.gagujarati = 0x0a97;
  t.gagurmukhi = 0x0a17;
  t.gahiragana = 0x304c;
  t.gakatakana = 0x30ac;
  t.gamma = 0x03b3;
  t.gammalatinsmall = 0x0263;
  t.gammasuperior = 0x02e0;
  t.gangiacoptic = 0x03eb;
  t.gbopomofo = 0x310d;
  t.gbreve = 0x011f;
  t.gcaron = 0x01e7;
  t.gcedilla = 0x0123;
  t.gcircle = 0x24d6;
  t.gcircumflex = 0x011d;
  t.gcommaaccent = 0x0123;
  t.gdot = 0x0121;
  t.gdotaccent = 0x0121;
  t.gecyrillic = 0x0433;
  t.gehiragana = 0x3052;
  t.gekatakana = 0x30b2;
  t.geometricallyequal = 0x2251;
  t.gereshaccenthebrew = 0x059c;
  t.gereshhebrew = 0x05f3;
  t.gereshmuqdamhebrew = 0x059d;
  t.germandbls = 0x00df;
  t.gershayimaccenthebrew = 0x059e;
  t.gershayimhebrew = 0x05f4;
  t.getamark = 0x3013;
  t.ghabengali = 0x0998;
  t.ghadarmenian = 0x0572;
  t.ghadeva = 0x0918;
  t.ghagujarati = 0x0a98;
  t.ghagurmukhi = 0x0a18;
  t.ghainarabic = 0x063a;
  t.ghainfinalarabic = 0xfece;
  t.ghaininitialarabic = 0xfecf;
  t.ghainmedialarabic = 0xfed0;
  t.ghemiddlehookcyrillic = 0x0495;
  t.ghestrokecyrillic = 0x0493;
  t.gheupturncyrillic = 0x0491;
  t.ghhadeva = 0x095a;
  t.ghhagurmukhi = 0x0a5a;
  t.ghook = 0x0260;
  t.ghzsquare = 0x3393;
  t.gihiragana = 0x304e;
  t.gikatakana = 0x30ae;
  t.gimarmenian = 0x0563;
  t.gimel = 0x05d2;
  t.gimeldagesh = 0xfb32;
  t.gimeldageshhebrew = 0xfb32;
  t.gimelhebrew = 0x05d2;
  t.gjecyrillic = 0x0453;
  t.glottalinvertedstroke = 0x01be;
  t.glottalstop = 0x0294;
  t.glottalstopinverted = 0x0296;
  t.glottalstopmod = 0x02c0;
  t.glottalstopreversed = 0x0295;
  t.glottalstopreversedmod = 0x02c1;
  t.glottalstopreversedsuperior = 0x02e4;
  t.glottalstopstroke = 0x02a1;
  t.glottalstopstrokereversed = 0x02a2;
  t.gmacron = 0x1e21;
  t.gmonospace = 0xff47;
  t.gohiragana = 0x3054;
  t.gokatakana = 0x30b4;
  t.gparen = 0x24a2;
  t.gpasquare = 0x33ac;
  t.gradient = 0x2207;
  t.grave = 0x0060;
  t.gravebelowcmb = 0x0316;
  t.gravecmb = 0x0300;
  t.gravecomb = 0x0300;
  t.gravedeva = 0x0953;
  t.gravelowmod = 0x02ce;
  t.gravemonospace = 0xff40;
  t.gravetonecmb = 0x0340;
  t.greater = 0x003e;
  t.greaterequal = 0x2265;
  t.greaterequalorless = 0x22db;
  t.greatermonospace = 0xff1e;
  t.greaterorequivalent = 0x2273;
  t.greaterorless = 0x2277;
  t.greateroverequal = 0x2267;
  t.greatersmall = 0xfe65;
  t.gscript = 0x0261;
  t.gstroke = 0x01e5;
  t.guhiragana = 0x3050;
  t.guillemotleft = 0x00ab;
  t.guillemotright = 0x00bb;
  t.guilsinglleft = 0x2039;
  t.guilsinglright = 0x203a;
  t.gukatakana = 0x30b0;
  t.guramusquare = 0x3318;
  t.gysquare = 0x33c9;
  t.h = 0x0068;
  t.haabkhasiancyrillic = 0x04a9;
  t.haaltonearabic = 0x06c1;
  t.habengali = 0x09b9;
  t.hadescendercyrillic = 0x04b3;
  t.hadeva = 0x0939;
  t.hagujarati = 0x0ab9;
  t.hagurmukhi = 0x0a39;
  t.haharabic = 0x062d;
  t.hahfinalarabic = 0xfea2;
  t.hahinitialarabic = 0xfea3;
  t.hahiragana = 0x306f;
  t.hahmedialarabic = 0xfea4;
  t.haitusquare = 0x332a;
  t.hakatakana = 0x30cf;
  t.hakatakanahalfwidth = 0xff8a;
  t.halantgurmukhi = 0x0a4d;
  t.hamzaarabic = 0x0621;
  t.hamzalowarabic = 0x0621;
  t.hangulfiller = 0x3164;
  t.hardsigncyrillic = 0x044a;
  t.harpoonleftbarbup = 0x21bc;
  t.harpoonrightbarbup = 0x21c0;
  t.hasquare = 0x33ca;
  t.hatafpatah = 0x05b2;
  t.hatafpatah16 = 0x05b2;
  t.hatafpatah23 = 0x05b2;
  t.hatafpatah2f = 0x05b2;
  t.hatafpatahhebrew = 0x05b2;
  t.hatafpatahnarrowhebrew = 0x05b2;
  t.hatafpatahquarterhebrew = 0x05b2;
  t.hatafpatahwidehebrew = 0x05b2;
  t.hatafqamats = 0x05b3;
  t.hatafqamats1b = 0x05b3;
  t.hatafqamats28 = 0x05b3;
  t.hatafqamats34 = 0x05b3;
  t.hatafqamatshebrew = 0x05b3;
  t.hatafqamatsnarrowhebrew = 0x05b3;
  t.hatafqamatsquarterhebrew = 0x05b3;
  t.hatafqamatswidehebrew = 0x05b3;
  t.hatafsegol = 0x05b1;
  t.hatafsegol17 = 0x05b1;
  t.hatafsegol24 = 0x05b1;
  t.hatafsegol30 = 0x05b1;
  t.hatafsegolhebrew = 0x05b1;
  t.hatafsegolnarrowhebrew = 0x05b1;
  t.hatafsegolquarterhebrew = 0x05b1;
  t.hatafsegolwidehebrew = 0x05b1;
  t.hbar = 0x0127;
  t.hbopomofo = 0x310f;
  t.hbrevebelow = 0x1e2b;
  t.hcedilla = 0x1e29;
  t.hcircle = 0x24d7;
  t.hcircumflex = 0x0125;
  t.hdieresis = 0x1e27;
  t.hdotaccent = 0x1e23;
  t.hdotbelow = 0x1e25;
  t.he = 0x05d4;
  t.heart = 0x2665;
  t.heartsuitblack = 0x2665;
  t.heartsuitwhite = 0x2661;
  t.hedagesh = 0xfb34;
  t.hedageshhebrew = 0xfb34;
  t.hehaltonearabic = 0x06c1;
  t.heharabic = 0x0647;
  t.hehebrew = 0x05d4;
  t.hehfinalaltonearabic = 0xfba7;
  t.hehfinalalttwoarabic = 0xfeea;
  t.hehfinalarabic = 0xfeea;
  t.hehhamzaabovefinalarabic = 0xfba5;
  t.hehhamzaaboveisolatedarabic = 0xfba4;
  t.hehinitialaltonearabic = 0xfba8;
  t.hehinitialarabic = 0xfeeb;
  t.hehiragana = 0x3078;
  t.hehmedialaltonearabic = 0xfba9;
  t.hehmedialarabic = 0xfeec;
  t.heiseierasquare = 0x337b;
  t.hekatakana = 0x30d8;
  t.hekatakanahalfwidth = 0xff8d;
  t.hekutaarusquare = 0x3336;
  t.henghook = 0x0267;
  t.herutusquare = 0x3339;
  t.het = 0x05d7;
  t.hethebrew = 0x05d7;
  t.hhook = 0x0266;
  t.hhooksuperior = 0x02b1;
  t.hieuhacirclekorean = 0x327b;
  t.hieuhaparenkorean = 0x321b;
  t.hieuhcirclekorean = 0x326d;
  t.hieuhkorean = 0x314e;
  t.hieuhparenkorean = 0x320d;
  t.hihiragana = 0x3072;
  t.hikatakana = 0x30d2;
  t.hikatakanahalfwidth = 0xff8b;
  t.hiriq = 0x05b4;
  t.hiriq14 = 0x05b4;
  t.hiriq21 = 0x05b4;
  t.hiriq2d = 0x05b4;
  t.hiriqhebrew = 0x05b4;
  t.hiriqnarrowhebrew = 0x05b4;
  t.hiriqquarterhebrew = 0x05b4;
  t.hiriqwidehebrew = 0x05b4;
  t.hlinebelow = 0x1e96;
  t.hmonospace = 0xff48;
  t.hoarmenian = 0x0570;
  t.hohipthai = 0x0e2b;
  t.hohiragana = 0x307b;
  t.hokatakana = 0x30db;
  t.hokatakanahalfwidth = 0xff8e;
  t.holam = 0x05b9;
  t.holam19 = 0x05b9;
  t.holam26 = 0x05b9;
  t.holam32 = 0x05b9;
  t.holamhebrew = 0x05b9;
  t.holamnarrowhebrew = 0x05b9;
  t.holamquarterhebrew = 0x05b9;
  t.holamwidehebrew = 0x05b9;
  t.honokhukthai = 0x0e2e;
  t.hookabovecomb = 0x0309;
  t.hookcmb = 0x0309;
  t.hookpalatalizedbelowcmb = 0x0321;
  t.hookretroflexbelowcmb = 0x0322;
  t.hoonsquare = 0x3342;
  t.horicoptic = 0x03e9;
  t.horizontalbar = 0x2015;
  t.horncmb = 0x031b;
  t.hotsprings = 0x2668;
  t.house = 0x2302;
  t.hparen = 0x24a3;
  t.hsuperior = 0x02b0;
  t.hturned = 0x0265;
  t.huhiragana = 0x3075;
  t.huiitosquare = 0x3333;
  t.hukatakana = 0x30d5;
  t.hukatakanahalfwidth = 0xff8c;
  t.hungarumlaut = 0x02dd;
  t.hungarumlautcmb = 0x030b;
  t.hv = 0x0195;
  t.hyphen = 0x002d;
  t.hypheninferior = 0xf6e5;
  t.hyphenmonospace = 0xff0d;
  t.hyphensmall = 0xfe63;
  t.hyphensuperior = 0xf6e6;
  t.hyphentwo = 0x2010;
  t.i = 0x0069;
  t.iacute = 0x00ed;
  t.iacyrillic = 0x044f;
  t.ibengali = 0x0987;
  t.ibopomofo = 0x3127;
  t.ibreve = 0x012d;
  t.icaron = 0x01d0;
  t.icircle = 0x24d8;
  t.icircumflex = 0x00ee;
  t.icyrillic = 0x0456;
  t.idblgrave = 0x0209;
  t.ideographearthcircle = 0x328f;
  t.ideographfirecircle = 0x328b;
  t.ideographicallianceparen = 0x323f;
  t.ideographiccallparen = 0x323a;
  t.ideographiccentrecircle = 0x32a5;
  t.ideographicclose = 0x3006;
  t.ideographiccomma = 0x3001;
  t.ideographiccommaleft = 0xff64;
  t.ideographiccongratulationparen = 0x3237;
  t.ideographiccorrectcircle = 0x32a3;
  t.ideographicearthparen = 0x322f;
  t.ideographicenterpriseparen = 0x323d;
  t.ideographicexcellentcircle = 0x329d;
  t.ideographicfestivalparen = 0x3240;
  t.ideographicfinancialcircle = 0x3296;
  t.ideographicfinancialparen = 0x3236;
  t.ideographicfireparen = 0x322b;
  t.ideographichaveparen = 0x3232;
  t.ideographichighcircle = 0x32a4;
  t.ideographiciterationmark = 0x3005;
  t.ideographiclaborcircle = 0x3298;
  t.ideographiclaborparen = 0x3238;
  t.ideographicleftcircle = 0x32a7;
  t.ideographiclowcircle = 0x32a6;
  t.ideographicmedicinecircle = 0x32a9;
  t.ideographicmetalparen = 0x322e;
  t.ideographicmoonparen = 0x322a;
  t.ideographicnameparen = 0x3234;
  t.ideographicperiod = 0x3002;
  t.ideographicprintcircle = 0x329e;
  t.ideographicreachparen = 0x3243;
  t.ideographicrepresentparen = 0x3239;
  t.ideographicresourceparen = 0x323e;
  t.ideographicrightcircle = 0x32a8;
  t.ideographicsecretcircle = 0x3299;
  t.ideographicselfparen = 0x3242;
  t.ideographicsocietyparen = 0x3233;
  t.ideographicspace = 0x3000;
  t.ideographicspecialparen = 0x3235;
  t.ideographicstockparen = 0x3231;
  t.ideographicstudyparen = 0x323b;
  t.ideographicsunparen = 0x3230;
  t.ideographicsuperviseparen = 0x323c;
  t.ideographicwaterparen = 0x322c;
  t.ideographicwoodparen = 0x322d;
  t.ideographiczero = 0x3007;
  t.ideographmetalcircle = 0x328e;
  t.ideographmooncircle = 0x328a;
  t.ideographnamecircle = 0x3294;
  t.ideographsuncircle = 0x3290;
  t.ideographwatercircle = 0x328c;
  t.ideographwoodcircle = 0x328d;
  t.ideva = 0x0907;
  t.idieresis = 0x00ef;
  t.idieresisacute = 0x1e2f;
  t.idieresiscyrillic = 0x04e5;
  t.idotbelow = 0x1ecb;
  t.iebrevecyrillic = 0x04d7;
  t.iecyrillic = 0x0435;
  t.ieungacirclekorean = 0x3275;
  t.ieungaparenkorean = 0x3215;
  t.ieungcirclekorean = 0x3267;
  t.ieungkorean = 0x3147;
  t.ieungparenkorean = 0x3207;
  t.igrave = 0x00ec;
  t.igujarati = 0x0a87;
  t.igurmukhi = 0x0a07;
  t.ihiragana = 0x3044;
  t.ihookabove = 0x1ec9;
  t.iibengali = 0x0988;
  t.iicyrillic = 0x0438;
  t.iideva = 0x0908;
  t.iigujarati = 0x0a88;
  t.iigurmukhi = 0x0a08;
  t.iimatragurmukhi = 0x0a40;
  t.iinvertedbreve = 0x020b;
  t.iishortcyrillic = 0x0439;
  t.iivowelsignbengali = 0x09c0;
  t.iivowelsigndeva = 0x0940;
  t.iivowelsigngujarati = 0x0ac0;
  t.ij = 0x0133;
  t.ikatakana = 0x30a4;
  t.ikatakanahalfwidth = 0xff72;
  t.ikorean = 0x3163;
  t.ilde = 0x02dc;
  t.iluyhebrew = 0x05ac;
  t.imacron = 0x012b;
  t.imacroncyrillic = 0x04e3;
  t.imageorapproximatelyequal = 0x2253;
  t.imatragurmukhi = 0x0a3f;
  t.imonospace = 0xff49;
  t.increment = 0x2206;
  t.infinity = 0x221e;
  t.iniarmenian = 0x056b;
  t.integral = 0x222b;
  t.integralbottom = 0x2321;
  t.integralbt = 0x2321;
  t.integralex = 0xf8f5;
  t.integraltop = 0x2320;
  t.integraltp = 0x2320;
  t.intersection = 0x2229;
  t.intisquare = 0x3305;
  t.invbullet = 0x25d8;
  t.invcircle = 0x25d9;
  t.invsmileface = 0x263b;
  t.iocyrillic = 0x0451;
  t.iogonek = 0x012f;
  t.iota = 0x03b9;
  t.iotadieresis = 0x03ca;
  t.iotadieresistonos = 0x0390;
  t.iotalatin = 0x0269;
  t.iotatonos = 0x03af;
  t.iparen = 0x24a4;
  t.irigurmukhi = 0x0a72;
  t.ismallhiragana = 0x3043;
  t.ismallkatakana = 0x30a3;
  t.ismallkatakanahalfwidth = 0xff68;
  t.issharbengali = 0x09fa;
  t.istroke = 0x0268;
  t.isuperior = 0xf6ed;
  t.iterationhiragana = 0x309d;
  t.iterationkatakana = 0x30fd;
  t.itilde = 0x0129;
  t.itildebelow = 0x1e2d;
  t.iubopomofo = 0x3129;
  t.iucyrillic = 0x044e;
  t.ivowelsignbengali = 0x09bf;
  t.ivowelsigndeva = 0x093f;
  t.ivowelsigngujarati = 0x0abf;
  t.izhitsacyrillic = 0x0475;
  t.izhitsadblgravecyrillic = 0x0477;
  t.j = 0x006a;
  t.jaarmenian = 0x0571;
  t.jabengali = 0x099c;
  t.jadeva = 0x091c;
  t.jagujarati = 0x0a9c;
  t.jagurmukhi = 0x0a1c;
  t.jbopomofo = 0x3110;
  t.jcaron = 0x01f0;
  t.jcircle = 0x24d9;
  t.jcircumflex = 0x0135;
  t.jcrossedtail = 0x029d;
  t.jdotlessstroke = 0x025f;
  t.jecyrillic = 0x0458;
  t.jeemarabic = 0x062c;
  t.jeemfinalarabic = 0xfe9e;
  t.jeeminitialarabic = 0xfe9f;
  t.jeemmedialarabic = 0xfea0;
  t.jeharabic = 0x0698;
  t.jehfinalarabic = 0xfb8b;
  t.jhabengali = 0x099d;
  t.jhadeva = 0x091d;
  t.jhagujarati = 0x0a9d;
  t.jhagurmukhi = 0x0a1d;
  t.jheharmenian = 0x057b;
  t.jis = 0x3004;
  t.jmonospace = 0xff4a;
  t.jparen = 0x24a5;
  t.jsuperior = 0x02b2;
  t.k = 0x006b;
  t.kabashkircyrillic = 0x04a1;
  t.kabengali = 0x0995;
  t.kacute = 0x1e31;
  t.kacyrillic = 0x043a;
  t.kadescendercyrillic = 0x049b;
  t.kadeva = 0x0915;
  t.kaf = 0x05db;
  t.kafarabic = 0x0643;
  t.kafdagesh = 0xfb3b;
  t.kafdageshhebrew = 0xfb3b;
  t.kaffinalarabic = 0xfeda;
  t.kafhebrew = 0x05db;
  t.kafinitialarabic = 0xfedb;
  t.kafmedialarabic = 0xfedc;
  t.kafrafehebrew = 0xfb4d;
  t.kagujarati = 0x0a95;
  t.kagurmukhi = 0x0a15;
  t.kahiragana = 0x304b;
  t.kahookcyrillic = 0x04c4;
  t.kakatakana = 0x30ab;
  t.kakatakanahalfwidth = 0xff76;
  t.kappa = 0x03ba;
  t.kappasymbolgreek = 0x03f0;
  t.kapyeounmieumkorean = 0x3171;
  t.kapyeounphieuphkorean = 0x3184;
  t.kapyeounpieupkorean = 0x3178;
  t.kapyeounssangpieupkorean = 0x3179;
  t.karoriisquare = 0x330d;
  t.kashidaautoarabic = 0x0640;
  t.kashidaautonosidebearingarabic = 0x0640;
  t.kasmallkatakana = 0x30f5;
  t.kasquare = 0x3384;
  t.kasraarabic = 0x0650;
  t.kasratanarabic = 0x064d;
  t.kastrokecyrillic = 0x049f;
  t.katahiraprolongmarkhalfwidth = 0xff70;
  t.kaverticalstrokecyrillic = 0x049d;
  t.kbopomofo = 0x310e;
  t.kcalsquare = 0x3389;
  t.kcaron = 0x01e9;
  t.kcedilla = 0x0137;
  t.kcircle = 0x24da;
  t.kcommaaccent = 0x0137;
  t.kdotbelow = 0x1e33;
  t.keharmenian = 0x0584;
  t.kehiragana = 0x3051;
  t.kekatakana = 0x30b1;
  t.kekatakanahalfwidth = 0xff79;
  t.kenarmenian = 0x056f;
  t.kesmallkatakana = 0x30f6;
  t.kgreenlandic = 0x0138;
  t.khabengali = 0x0996;
  t.khacyrillic = 0x0445;
  t.khadeva = 0x0916;
  t.khagujarati = 0x0a96;
  t.khagurmukhi = 0x0a16;
  t.khaharabic = 0x062e;
  t.khahfinalarabic = 0xfea6;
  t.khahinitialarabic = 0xfea7;
  t.khahmedialarabic = 0xfea8;
  t.kheicoptic = 0x03e7;
  t.khhadeva = 0x0959;
  t.khhagurmukhi = 0x0a59;
  t.khieukhacirclekorean = 0x3278;
  t.khieukhaparenkorean = 0x3218;
  t.khieukhcirclekorean = 0x326a;
  t.khieukhkorean = 0x314b;
  t.khieukhparenkorean = 0x320a;
  t.khokhaithai = 0x0e02;
  t.khokhonthai = 0x0e05;
  t.khokhuatthai = 0x0e03;
  t.khokhwaithai = 0x0e04;
  t.khomutthai = 0x0e5b;
  t.khook = 0x0199;
  t.khorakhangthai = 0x0e06;
  t.khzsquare = 0x3391;
  t.kihiragana = 0x304d;
  t.kikatakana = 0x30ad;
  t.kikatakanahalfwidth = 0xff77;
  t.kiroguramusquare = 0x3315;
  t.kiromeetorusquare = 0x3316;
  t.kirosquare = 0x3314;
  t.kiyeokacirclekorean = 0x326e;
  t.kiyeokaparenkorean = 0x320e;
  t.kiyeokcirclekorean = 0x3260;
  t.kiyeokkorean = 0x3131;
  t.kiyeokparenkorean = 0x3200;
  t.kiyeoksioskorean = 0x3133;
  t.kjecyrillic = 0x045c;
  t.klinebelow = 0x1e35;
  t.klsquare = 0x3398;
  t.kmcubedsquare = 0x33a6;
  t.kmonospace = 0xff4b;
  t.kmsquaredsquare = 0x33a2;
  t.kohiragana = 0x3053;
  t.kohmsquare = 0x33c0;
  t.kokaithai = 0x0e01;
  t.kokatakana = 0x30b3;
  t.kokatakanahalfwidth = 0xff7a;
  t.kooposquare = 0x331e;
  t.koppacyrillic = 0x0481;
  t.koreanstandardsymbol = 0x327f;
  t.koroniscmb = 0x0343;
  t.kparen = 0x24a6;
  t.kpasquare = 0x33aa;
  t.ksicyrillic = 0x046f;
  t.ktsquare = 0x33cf;
  t.kturned = 0x029e;
  t.kuhiragana = 0x304f;
  t.kukatakana = 0x30af;
  t.kukatakanahalfwidth = 0xff78;
  t.kvsquare = 0x33b8;
  t.kwsquare = 0x33be;
  t.l = 0x006c;
  t.labengali = 0x09b2;
  t.lacute = 0x013a;
  t.ladeva = 0x0932;
  t.lagujarati = 0x0ab2;
  t.lagurmukhi = 0x0a32;
  t.lakkhangyaothai = 0x0e45;
  t.lamaleffinalarabic = 0xfefc;
  t.lamalefhamzaabovefinalarabic = 0xfef8;
  t.lamalefhamzaaboveisolatedarabic = 0xfef7;
  t.lamalefhamzabelowfinalarabic = 0xfefa;
  t.lamalefhamzabelowisolatedarabic = 0xfef9;
  t.lamalefisolatedarabic = 0xfefb;
  t.lamalefmaddaabovefinalarabic = 0xfef6;
  t.lamalefmaddaaboveisolatedarabic = 0xfef5;
  t.lamarabic = 0x0644;
  t.lambda = 0x03bb;
  t.lambdastroke = 0x019b;
  t.lamed = 0x05dc;
  t.lameddagesh = 0xfb3c;
  t.lameddageshhebrew = 0xfb3c;
  t.lamedhebrew = 0x05dc;
  t.lamfinalarabic = 0xfede;
  t.lamhahinitialarabic = 0xfcca;
  t.laminitialarabic = 0xfedf;
  t.lamjeeminitialarabic = 0xfcc9;
  t.lamkhahinitialarabic = 0xfccb;
  t.lamlamhehisolatedarabic = 0xfdf2;
  t.lammedialarabic = 0xfee0;
  t.lammeemhahinitialarabic = 0xfd88;
  t.lammeeminitialarabic = 0xfccc;
  t.largecircle = 0x25ef;
  t.lbar = 0x019a;
  t.lbelt = 0x026c;
  t.lbopomofo = 0x310c;
  t.lcaron = 0x013e;
  t.lcedilla = 0x013c;
  t.lcircle = 0x24db;
  t.lcircumflexbelow = 0x1e3d;
  t.lcommaaccent = 0x013c;
  t.ldot = 0x0140;
  t.ldotaccent = 0x0140;
  t.ldotbelow = 0x1e37;
  t.ldotbelowmacron = 0x1e39;
  t.leftangleabovecmb = 0x031a;
  t.lefttackbelowcmb = 0x0318;
  t.less = 0x003c;
  t.lessequal = 0x2264;
  t.lessequalorgreater = 0x22da;
  t.lessmonospace = 0xff1c;
  t.lessorequivalent = 0x2272;
  t.lessorgreater = 0x2276;
  t.lessoverequal = 0x2266;
  t.lesssmall = 0xfe64;
  t.lezh = 0x026e;
  t.lfblock = 0x258c;
  t.lhookretroflex = 0x026d;
  t.lira = 0x20a4;
  t.liwnarmenian = 0x056c;
  t.lj = 0x01c9;
  t.ljecyrillic = 0x0459;
  t.ll = 0xf6c0;
  t.lladeva = 0x0933;
  t.llagujarati = 0x0ab3;
  t.llinebelow = 0x1e3b;
  t.llladeva = 0x0934;
  t.llvocalicbengali = 0x09e1;
  t.llvocalicdeva = 0x0961;
  t.llvocalicvowelsignbengali = 0x09e3;
  t.llvocalicvowelsigndeva = 0x0963;
  t.lmiddletilde = 0x026b;
  t.lmonospace = 0xff4c;
  t.lmsquare = 0x33d0;
  t.lochulathai = 0x0e2c;
  t.logicaland = 0x2227;
  t.logicalnot = 0x00ac;
  t.logicalnotreversed = 0x2310;
  t.logicalor = 0x2228;
  t.lolingthai = 0x0e25;
  t.longs = 0x017f;
  t.lowlinecenterline = 0xfe4e;
  t.lowlinecmb = 0x0332;
  t.lowlinedashed = 0xfe4d;
  t.lozenge = 0x25ca;
  t.lparen = 0x24a7;
  t.lslash = 0x0142;
  t.lsquare = 0x2113;
  t.lsuperior = 0xf6ee;
  t.ltshade = 0x2591;
  t.luthai = 0x0e26;
  t.lvocalicbengali = 0x098c;
  t.lvocalicdeva = 0x090c;
  t.lvocalicvowelsignbengali = 0x09e2;
  t.lvocalicvowelsigndeva = 0x0962;
  t.lxsquare = 0x33d3;
  t.m = 0x006d;
  t.mabengali = 0x09ae;
  t.macron = 0x00af;
  t.macronbelowcmb = 0x0331;
  t.macroncmb = 0x0304;
  t.macronlowmod = 0x02cd;
  t.macronmonospace = 0xffe3;
  t.macute = 0x1e3f;
  t.madeva = 0x092e;
  t.magujarati = 0x0aae;
  t.magurmukhi = 0x0a2e;
  t.mahapakhhebrew = 0x05a4;
  t.mahapakhlefthebrew = 0x05a4;
  t.mahiragana = 0x307e;
  t.maichattawalowleftthai = 0xf895;
  t.maichattawalowrightthai = 0xf894;
  t.maichattawathai = 0x0e4b;
  t.maichattawaupperleftthai = 0xf893;
  t.maieklowleftthai = 0xf88c;
  t.maieklowrightthai = 0xf88b;
  t.maiekthai = 0x0e48;
  t.maiekupperleftthai = 0xf88a;
  t.maihanakatleftthai = 0xf884;
  t.maihanakatthai = 0x0e31;
  t.maitaikhuleftthai = 0xf889;
  t.maitaikhuthai = 0x0e47;
  t.maitholowleftthai = 0xf88f;
  t.maitholowrightthai = 0xf88e;
  t.maithothai = 0x0e49;
  t.maithoupperleftthai = 0xf88d;
  t.maitrilowleftthai = 0xf892;
  t.maitrilowrightthai = 0xf891;
  t.maitrithai = 0x0e4a;
  t.maitriupperleftthai = 0xf890;
  t.maiyamokthai = 0x0e46;
  t.makatakana = 0x30de;
  t.makatakanahalfwidth = 0xff8f;
  t.male = 0x2642;
  t.mansyonsquare = 0x3347;
  t.maqafhebrew = 0x05be;
  t.mars = 0x2642;
  t.masoracirclehebrew = 0x05af;
  t.masquare = 0x3383;
  t.mbopomofo = 0x3107;
  t.mbsquare = 0x33d4;
  t.mcircle = 0x24dc;
  t.mcubedsquare = 0x33a5;
  t.mdotaccent = 0x1e41;
  t.mdotbelow = 0x1e43;
  t.meemarabic = 0x0645;
  t.meemfinalarabic = 0xfee2;
  t.meeminitialarabic = 0xfee3;
  t.meemmedialarabic = 0xfee4;
  t.meemmeeminitialarabic = 0xfcd1;
  t.meemmeemisolatedarabic = 0xfc48;
  t.meetorusquare = 0x334d;
  t.mehiragana = 0x3081;
  t.meizierasquare = 0x337e;
  t.mekatakana = 0x30e1;
  t.mekatakanahalfwidth = 0xff92;
  t.mem = 0x05de;
  t.memdagesh = 0xfb3e;
  t.memdageshhebrew = 0xfb3e;
  t.memhebrew = 0x05de;
  t.menarmenian = 0x0574;
  t.merkhahebrew = 0x05a5;
  t.merkhakefulahebrew = 0x05a6;
  t.merkhakefulalefthebrew = 0x05a6;
  t.merkhalefthebrew = 0x05a5;
  t.mhook = 0x0271;
  t.mhzsquare = 0x3392;
  t.middledotkatakanahalfwidth = 0xff65;
  t.middot = 0x00b7;
  t.mieumacirclekorean = 0x3272;
  t.mieumaparenkorean = 0x3212;
  t.mieumcirclekorean = 0x3264;
  t.mieumkorean = 0x3141;
  t.mieumpansioskorean = 0x3170;
  t.mieumparenkorean = 0x3204;
  t.mieumpieupkorean = 0x316e;
  t.mieumsioskorean = 0x316f;
  t.mihiragana = 0x307f;
  t.mikatakana = 0x30df;
  t.mikatakanahalfwidth = 0xff90;
  t.minus = 0x2212;
  t.minusbelowcmb = 0x0320;
  t.minuscircle = 0x2296;
  t.minusmod = 0x02d7;
  t.minusplus = 0x2213;
  t.minute = 0x2032;
  t.miribaarusquare = 0x334a;
  t.mirisquare = 0x3349;
  t.mlonglegturned = 0x0270;
  t.mlsquare = 0x3396;
  t.mmcubedsquare = 0x33a3;
  t.mmonospace = 0xff4d;
  t.mmsquaredsquare = 0x339f;
  t.mohiragana = 0x3082;
  t.mohmsquare = 0x33c1;
  t.mokatakana = 0x30e2;
  t.mokatakanahalfwidth = 0xff93;
  t.molsquare = 0x33d6;
  t.momathai = 0x0e21;
  t.moverssquare = 0x33a7;
  t.moverssquaredsquare = 0x33a8;
  t.mparen = 0x24a8;
  t.mpasquare = 0x33ab;
  t.mssquare = 0x33b3;
  t.msuperior = 0xf6ef;
  t.mturned = 0x026f;
  t.mu = 0x00b5;
  t.mu1 = 0x00b5;
  t.muasquare = 0x3382;
  t.muchgreater = 0x226b;
  t.muchless = 0x226a;
  t.mufsquare = 0x338c;
  t.mugreek = 0x03bc;
  t.mugsquare = 0x338d;
  t.muhiragana = 0x3080;
  t.mukatakana = 0x30e0;
  t.mukatakanahalfwidth = 0xff91;
  t.mulsquare = 0x3395;
  t.multiply = 0x00d7;
  t.mumsquare = 0x339b;
  t.munahhebrew = 0x05a3;
  t.munahlefthebrew = 0x05a3;
  t.musicalnote = 0x266a;
  t.musicalnotedbl = 0x266b;
  t.musicflatsign = 0x266d;
  t.musicsharpsign = 0x266f;
  t.mussquare = 0x33b2;
  t.muvsquare = 0x33b6;
  t.muwsquare = 0x33bc;
  t.mvmegasquare = 0x33b9;
  t.mvsquare = 0x33b7;
  t.mwmegasquare = 0x33bf;
  t.mwsquare = 0x33bd;
  t.n = 0x006e;
  t.nabengali = 0x09a8;
  t.nabla = 0x2207;
  t.nacute = 0x0144;
  t.nadeva = 0x0928;
  t.nagujarati = 0x0aa8;
  t.nagurmukhi = 0x0a28;
  t.nahiragana = 0x306a;
  t.nakatakana = 0x30ca;
  t.nakatakanahalfwidth = 0xff85;
  t.napostrophe = 0x0149;
  t.nasquare = 0x3381;
  t.nbopomofo = 0x310b;
  t.nbspace = 0x00a0;
  t.ncaron = 0x0148;
  t.ncedilla = 0x0146;
  t.ncircle = 0x24dd;
  t.ncircumflexbelow = 0x1e4b;
  t.ncommaaccent = 0x0146;
  t.ndotaccent = 0x1e45;
  t.ndotbelow = 0x1e47;
  t.nehiragana = 0x306d;
  t.nekatakana = 0x30cd;
  t.nekatakanahalfwidth = 0xff88;
  t.newsheqelsign = 0x20aa;
  t.nfsquare = 0x338b;
  t.ngabengali = 0x0999;
  t.ngadeva = 0x0919;
  t.ngagujarati = 0x0a99;
  t.ngagurmukhi = 0x0a19;
  t.ngonguthai = 0x0e07;
  t.nhiragana = 0x3093;
  t.nhookleft = 0x0272;
  t.nhookretroflex = 0x0273;
  t.nieunacirclekorean = 0x326f;
  t.nieunaparenkorean = 0x320f;
  t.nieuncieuckorean = 0x3135;
  t.nieuncirclekorean = 0x3261;
  t.nieunhieuhkorean = 0x3136;
  t.nieunkorean = 0x3134;
  t.nieunpansioskorean = 0x3168;
  t.nieunparenkorean = 0x3201;
  t.nieunsioskorean = 0x3167;
  t.nieuntikeutkorean = 0x3166;
  t.nihiragana = 0x306b;
  t.nikatakana = 0x30cb;
  t.nikatakanahalfwidth = 0xff86;
  t.nikhahitleftthai = 0xf899;
  t.nikhahitthai = 0x0e4d;
  t.nine = 0x0039;
  t.ninearabic = 0x0669;
  t.ninebengali = 0x09ef;
  t.ninecircle = 0x2468;
  t.ninecircleinversesansserif = 0x2792;
  t.ninedeva = 0x096f;
  t.ninegujarati = 0x0aef;
  t.ninegurmukhi = 0x0a6f;
  t.ninehackarabic = 0x0669;
  t.ninehangzhou = 0x3029;
  t.nineideographicparen = 0x3228;
  t.nineinferior = 0x2089;
  t.ninemonospace = 0xff19;
  t.nineoldstyle = 0xf739;
  t.nineparen = 0x247c;
  t.nineperiod = 0x2490;
  t.ninepersian = 0x06f9;
  t.nineroman = 0x2178;
  t.ninesuperior = 0x2079;
  t.nineteencircle = 0x2472;
  t.nineteenparen = 0x2486;
  t.nineteenperiod = 0x249a;
  t.ninethai = 0x0e59;
  t.nj = 0x01cc;
  t.njecyrillic = 0x045a;
  t.nkatakana = 0x30f3;
  t.nkatakanahalfwidth = 0xff9d;
  t.nlegrightlong = 0x019e;
  t.nlinebelow = 0x1e49;
  t.nmonospace = 0xff4e;
  t.nmsquare = 0x339a;
  t.nnabengali = 0x09a3;
  t.nnadeva = 0x0923;
  t.nnagujarati = 0x0aa3;
  t.nnagurmukhi = 0x0a23;
  t.nnnadeva = 0x0929;
  t.nohiragana = 0x306e;
  t.nokatakana = 0x30ce;
  t.nokatakanahalfwidth = 0xff89;
  t.nonbreakingspace = 0x00a0;
  t.nonenthai = 0x0e13;
  t.nonuthai = 0x0e19;
  t.noonarabic = 0x0646;
  t.noonfinalarabic = 0xfee6;
  t.noonghunnaarabic = 0x06ba;
  t.noonghunnafinalarabic = 0xfb9f;
  t.nooninitialarabic = 0xfee7;
  t.noonjeeminitialarabic = 0xfcd2;
  t.noonjeemisolatedarabic = 0xfc4b;
  t.noonmedialarabic = 0xfee8;
  t.noonmeeminitialarabic = 0xfcd5;
  t.noonmeemisolatedarabic = 0xfc4e;
  t.noonnoonfinalarabic = 0xfc8d;
  t.notcontains = 0x220c;
  t.notelement = 0x2209;
  t.notelementof = 0x2209;
  t.notequal = 0x2260;
  t.notgreater = 0x226f;
  t.notgreaternorequal = 0x2271;
  t.notgreaternorless = 0x2279;
  t.notidentical = 0x2262;
  t.notless = 0x226e;
  t.notlessnorequal = 0x2270;
  t.notparallel = 0x2226;
  t.notprecedes = 0x2280;
  t.notsubset = 0x2284;
  t.notsucceeds = 0x2281;
  t.notsuperset = 0x2285;
  t.nowarmenian = 0x0576;
  t.nparen = 0x24a9;
  t.nssquare = 0x33b1;
  t.nsuperior = 0x207f;
  t.ntilde = 0x00f1;
  t.nu = 0x03bd;
  t.nuhiragana = 0x306c;
  t.nukatakana = 0x30cc;
  t.nukatakanahalfwidth = 0xff87;
  t.nuktabengali = 0x09bc;
  t.nuktadeva = 0x093c;
  t.nuktagujarati = 0x0abc;
  t.nuktagurmukhi = 0x0a3c;
  t.numbersign = 0x0023;
  t.numbersignmonospace = 0xff03;
  t.numbersignsmall = 0xfe5f;
  t.numeralsigngreek = 0x0374;
  t.numeralsignlowergreek = 0x0375;
  t.numero = 0x2116;
  t.nun = 0x05e0;
  t.nundagesh = 0xfb40;
  t.nundageshhebrew = 0xfb40;
  t.nunhebrew = 0x05e0;
  t.nvsquare = 0x33b5;
  t.nwsquare = 0x33bb;
  t.nyabengali = 0x099e;
  t.nyadeva = 0x091e;
  t.nyagujarati = 0x0a9e;
  t.nyagurmukhi = 0x0a1e;
  t.o = 0x006f;
  t.oacute = 0x00f3;
  t.oangthai = 0x0e2d;
  t.obarred = 0x0275;
  t.obarredcyrillic = 0x04e9;
  t.obarreddieresiscyrillic = 0x04eb;
  t.obengali = 0x0993;
  t.obopomofo = 0x311b;
  t.obreve = 0x014f;
  t.ocandradeva = 0x0911;
  t.ocandragujarati = 0x0a91;
  t.ocandravowelsigndeva = 0x0949;
  t.ocandravowelsigngujarati = 0x0ac9;
  t.ocaron = 0x01d2;
  t.ocircle = 0x24de;
  t.ocircumflex = 0x00f4;
  t.ocircumflexacute = 0x1ed1;
  t.ocircumflexdotbelow = 0x1ed9;
  t.ocircumflexgrave = 0x1ed3;
  t.ocircumflexhookabove = 0x1ed5;
  t.ocircumflextilde = 0x1ed7;
  t.ocyrillic = 0x043e;
  t.odblacute = 0x0151;
  t.odblgrave = 0x020d;
  t.odeva = 0x0913;
  t.odieresis = 0x00f6;
  t.odieresiscyrillic = 0x04e7;
  t.odotbelow = 0x1ecd;
  t.oe = 0x0153;
  t.oekorean = 0x315a;
  t.ogonek = 0x02db;
  t.ogonekcmb = 0x0328;
  t.ograve = 0x00f2;
  t.ogujarati = 0x0a93;
  t.oharmenian = 0x0585;
  t.ohiragana = 0x304a;
  t.ohookabove = 0x1ecf;
  t.ohorn = 0x01a1;
  t.ohornacute = 0x1edb;
  t.ohorndotbelow = 0x1ee3;
  t.ohorngrave = 0x1edd;
  t.ohornhookabove = 0x1edf;
  t.ohorntilde = 0x1ee1;
  t.ohungarumlaut = 0x0151;
  t.oi = 0x01a3;
  t.oinvertedbreve = 0x020f;
  t.okatakana = 0x30aa;
  t.okatakanahalfwidth = 0xff75;
  t.okorean = 0x3157;
  t.olehebrew = 0x05ab;
  t.omacron = 0x014d;
  t.omacronacute = 0x1e53;
  t.omacrongrave = 0x1e51;
  t.omdeva = 0x0950;
  t.omega = 0x03c9;
  t.omega1 = 0x03d6;
  t.omegacyrillic = 0x0461;
  t.omegalatinclosed = 0x0277;
  t.omegaroundcyrillic = 0x047b;
  t.omegatitlocyrillic = 0x047d;
  t.omegatonos = 0x03ce;
  t.omgujarati = 0x0ad0;
  t.omicron = 0x03bf;
  t.omicrontonos = 0x03cc;
  t.omonospace = 0xff4f;
  t.one = 0x0031;
  t.onearabic = 0x0661;
  t.onebengali = 0x09e7;
  t.onecircle = 0x2460;
  t.onecircleinversesansserif = 0x278a;
  t.onedeva = 0x0967;
  t.onedotenleader = 0x2024;
  t.oneeighth = 0x215b;
  t.onefitted = 0xf6dc;
  t.onegujarati = 0x0ae7;
  t.onegurmukhi = 0x0a67;
  t.onehackarabic = 0x0661;
  t.onehalf = 0x00bd;
  t.onehangzhou = 0x3021;
  t.oneideographicparen = 0x3220;
  t.oneinferior = 0x2081;
  t.onemonospace = 0xff11;
  t.onenumeratorbengali = 0x09f4;
  t.oneoldstyle = 0xf731;
  t.oneparen = 0x2474;
  t.oneperiod = 0x2488;
  t.onepersian = 0x06f1;
  t.onequarter = 0x00bc;
  t.oneroman = 0x2170;
  t.onesuperior = 0x00b9;
  t.onethai = 0x0e51;
  t.onethird = 0x2153;
  t.oogonek = 0x01eb;
  t.oogonekmacron = 0x01ed;
  t.oogurmukhi = 0x0a13;
  t.oomatragurmukhi = 0x0a4b;
  t.oopen = 0x0254;
  t.oparen = 0x24aa;
  t.openbullet = 0x25e6;
  t.option = 0x2325;
  t.ordfeminine = 0x00aa;
  t.ordmasculine = 0x00ba;
  t.orthogonal = 0x221f;
  t.oshortdeva = 0x0912;
  t.oshortvowelsigndeva = 0x094a;
  t.oslash = 0x00f8;
  t.oslashacute = 0x01ff;
  t.osmallhiragana = 0x3049;
  t.osmallkatakana = 0x30a9;
  t.osmallkatakanahalfwidth = 0xff6b;
  t.ostrokeacute = 0x01ff;
  t.osuperior = 0xf6f0;
  t.otcyrillic = 0x047f;
  t.otilde = 0x00f5;
  t.otildeacute = 0x1e4d;
  t.otildedieresis = 0x1e4f;
  t.oubopomofo = 0x3121;
  t.overline = 0x203e;
  t.overlinecenterline = 0xfe4a;
  t.overlinecmb = 0x0305;
  t.overlinedashed = 0xfe49;
  t.overlinedblwavy = 0xfe4c;
  t.overlinewavy = 0xfe4b;
  t.overscore = 0x00af;
  t.ovowelsignbengali = 0x09cb;
  t.ovowelsigndeva = 0x094b;
  t.ovowelsigngujarati = 0x0acb;
  t.p = 0x0070;
  t.paampssquare = 0x3380;
  t.paasentosquare = 0x332b;
  t.pabengali = 0x09aa;
  t.pacute = 0x1e55;
  t.padeva = 0x092a;
  t.pagedown = 0x21df;
  t.pageup = 0x21de;
  t.pagujarati = 0x0aaa;
  t.pagurmukhi = 0x0a2a;
  t.pahiragana = 0x3071;
  t.paiyannoithai = 0x0e2f;
  t.pakatakana = 0x30d1;
  t.palatalizationcyrilliccmb = 0x0484;
  t.palochkacyrillic = 0x04c0;
  t.pansioskorean = 0x317f;
  t.paragraph = 0x00b6;
  t.parallel = 0x2225;
  t.parenleft = 0x0028;
  t.parenleftaltonearabic = 0xfd3e;
  t.parenleftbt = 0xf8ed;
  t.parenleftex = 0xf8ec;
  t.parenleftinferior = 0x208d;
  t.parenleftmonospace = 0xff08;
  t.parenleftsmall = 0xfe59;
  t.parenleftsuperior = 0x207d;
  t.parenlefttp = 0xf8eb;
  t.parenleftvertical = 0xfe35;
  t.parenright = 0x0029;
  t.parenrightaltonearabic = 0xfd3f;
  t.parenrightbt = 0xf8f8;
  t.parenrightex = 0xf8f7;
  t.parenrightinferior = 0x208e;
  t.parenrightmonospace = 0xff09;
  t.parenrightsmall = 0xfe5a;
  t.parenrightsuperior = 0x207e;
  t.parenrighttp = 0xf8f6;
  t.parenrightvertical = 0xfe36;
  t.partialdiff = 0x2202;
  t.paseqhebrew = 0x05c0;
  t.pashtahebrew = 0x0599;
  t.pasquare = 0x33a9;
  t.patah = 0x05b7;
  t.patah11 = 0x05b7;
  t.patah1d = 0x05b7;
  t.patah2a = 0x05b7;
  t.patahhebrew = 0x05b7;
  t.patahnarrowhebrew = 0x05b7;
  t.patahquarterhebrew = 0x05b7;
  t.patahwidehebrew = 0x05b7;
  t.pazerhebrew = 0x05a1;
  t.pbopomofo = 0x3106;
  t.pcircle = 0x24df;
  t.pdotaccent = 0x1e57;
  t.pe = 0x05e4;
  t.pecyrillic = 0x043f;
  t.pedagesh = 0xfb44;
  t.pedageshhebrew = 0xfb44;
  t.peezisquare = 0x333b;
  t.pefinaldageshhebrew = 0xfb43;
  t.peharabic = 0x067e;
  t.peharmenian = 0x057a;
  t.pehebrew = 0x05e4;
  t.pehfinalarabic = 0xfb57;
  t.pehinitialarabic = 0xfb58;
  t.pehiragana = 0x307a;
  t.pehmedialarabic = 0xfb59;
  t.pekatakana = 0x30da;
  t.pemiddlehookcyrillic = 0x04a7;
  t.perafehebrew = 0xfb4e;
  t.percent = 0x0025;
  t.percentarabic = 0x066a;
  t.percentmonospace = 0xff05;
  t.percentsmall = 0xfe6a;
  t.period = 0x002e;
  t.periodarmenian = 0x0589;
  t.periodcentered = 0x00b7;
  t.periodhalfwidth = 0xff61;
  t.periodinferior = 0xf6e7;
  t.periodmonospace = 0xff0e;
  t.periodsmall = 0xfe52;
  t.periodsuperior = 0xf6e8;
  t.perispomenigreekcmb = 0x0342;
  t.perpendicular = 0x22a5;
  t.perthousand = 0x2030;
  t.peseta = 0x20a7;
  t.pfsquare = 0x338a;
  t.phabengali = 0x09ab;
  t.phadeva = 0x092b;
  t.phagujarati = 0x0aab;
  t.phagurmukhi = 0x0a2b;
  t.phi = 0x03c6;
  t.phi1 = 0x03d5;
  t.phieuphacirclekorean = 0x327a;
  t.phieuphaparenkorean = 0x321a;
  t.phieuphcirclekorean = 0x326c;
  t.phieuphkorean = 0x314d;
  t.phieuphparenkorean = 0x320c;
  t.philatin = 0x0278;
  t.phinthuthai = 0x0e3a;
  t.phisymbolgreek = 0x03d5;
  t.phook = 0x01a5;
  t.phophanthai = 0x0e1e;
  t.phophungthai = 0x0e1c;
  t.phosamphaothai = 0x0e20;
  t.pi = 0x03c0;
  t.pieupacirclekorean = 0x3273;
  t.pieupaparenkorean = 0x3213;
  t.pieupcieuckorean = 0x3176;
  t.pieupcirclekorean = 0x3265;
  t.pieupkiyeokkorean = 0x3172;
  t.pieupkorean = 0x3142;
  t.pieupparenkorean = 0x3205;
  t.pieupsioskiyeokkorean = 0x3174;
  t.pieupsioskorean = 0x3144;
  t.pieupsiostikeutkorean = 0x3175;
  t.pieupthieuthkorean = 0x3177;
  t.pieuptikeutkorean = 0x3173;
  t.pihiragana = 0x3074;
  t.pikatakana = 0x30d4;
  t.pisymbolgreek = 0x03d6;
  t.piwrarmenian = 0x0583;
  t.planckover2pi = 0x210f;
  t.planckover2pi1 = 0x210f;
  t.plus = 0x002b;
  t.plusbelowcmb = 0x031f;
  t.pluscircle = 0x2295;
  t.plusminus = 0x00b1;
  t.plusmod = 0x02d6;
  t.plusmonospace = 0xff0b;
  t.plussmall = 0xfe62;
  t.plussuperior = 0x207a;
  t.pmonospace = 0xff50;
  t.pmsquare = 0x33d8;
  t.pohiragana = 0x307d;
  t.pointingindexdownwhite = 0x261f;
  t.pointingindexleftwhite = 0x261c;
  t.pointingindexrightwhite = 0x261e;
  t.pointingindexupwhite = 0x261d;
  t.pokatakana = 0x30dd;
  t.poplathai = 0x0e1b;
  t.postalmark = 0x3012;
  t.postalmarkface = 0x3020;
  t.pparen = 0x24ab;
  t.precedes = 0x227a;
  t.prescription = 0x211e;
  t.primemod = 0x02b9;
  t.primereversed = 0x2035;
  t.product = 0x220f;
  t.projective = 0x2305;
  t.prolongedkana = 0x30fc;
  t.propellor = 0x2318;
  t.propersubset = 0x2282;
  t.propersuperset = 0x2283;
  t.proportion = 0x2237;
  t.proportional = 0x221d;
  t.psi = 0x03c8;
  t.psicyrillic = 0x0471;
  t.psilipneumatacyrilliccmb = 0x0486;
  t.pssquare = 0x33b0;
  t.puhiragana = 0x3077;
  t.pukatakana = 0x30d7;
  t.pvsquare = 0x33b4;
  t.pwsquare = 0x33ba;
  t.q = 0x0071;
  t.qadeva = 0x0958;
  t.qadmahebrew = 0x05a8;
  t.qafarabic = 0x0642;
  t.qaffinalarabic = 0xfed6;
  t.qafinitialarabic = 0xfed7;
  t.qafmedialarabic = 0xfed8;
  t.qamats = 0x05b8;
  t.qamats10 = 0x05b8;
  t.qamats1a = 0x05b8;
  t.qamats1c = 0x05b8;
  t.qamats27 = 0x05b8;
  t.qamats29 = 0x05b8;
  t.qamats33 = 0x05b8;
  t.qamatsde = 0x05b8;
  t.qamatshebrew = 0x05b8;
  t.qamatsnarrowhebrew = 0x05b8;
  t.qamatsqatanhebrew = 0x05b8;
  t.qamatsqatannarrowhebrew = 0x05b8;
  t.qamatsqatanquarterhebrew = 0x05b8;
  t.qamatsqatanwidehebrew = 0x05b8;
  t.qamatsquarterhebrew = 0x05b8;
  t.qamatswidehebrew = 0x05b8;
  t.qarneyparahebrew = 0x059f;
  t.qbopomofo = 0x3111;
  t.qcircle = 0x24e0;
  t.qhook = 0x02a0;
  t.qmonospace = 0xff51;
  t.qof = 0x05e7;
  t.qofdagesh = 0xfb47;
  t.qofdageshhebrew = 0xfb47;
  t.qofhebrew = 0x05e7;
  t.qparen = 0x24ac;
  t.quarternote = 0x2669;
  t.qubuts = 0x05bb;
  t.qubuts18 = 0x05bb;
  t.qubuts25 = 0x05bb;
  t.qubuts31 = 0x05bb;
  t.qubutshebrew = 0x05bb;
  t.qubutsnarrowhebrew = 0x05bb;
  t.qubutsquarterhebrew = 0x05bb;
  t.qubutswidehebrew = 0x05bb;
  t.question = 0x003f;
  t.questionarabic = 0x061f;
  t.questionarmenian = 0x055e;
  t.questiondown = 0x00bf;
  t.questiondownsmall = 0xf7bf;
  t.questiongreek = 0x037e;
  t.questionmonospace = 0xff1f;
  t.questionsmall = 0xf73f;
  t.quotedbl = 0x0022;
  t.quotedblbase = 0x201e;
  t.quotedblleft = 0x201c;
  t.quotedblmonospace = 0xff02;
  t.quotedblprime = 0x301e;
  t.quotedblprimereversed = 0x301d;
  t.quotedblright = 0x201d;
  t.quoteleft = 0x2018;
  t.quoteleftreversed = 0x201b;
  t.quotereversed = 0x201b;
  t.quoteright = 0x2019;
  t.quoterightn = 0x0149;
  t.quotesinglbase = 0x201a;
  t.quotesingle = 0x0027;
  t.quotesinglemonospace = 0xff07;
  t.r = 0x0072;
  t.raarmenian = 0x057c;
  t.rabengali = 0x09b0;
  t.racute = 0x0155;
  t.radeva = 0x0930;
  t.radical = 0x221a;
  t.radicalex = 0xf8e5;
  t.radoverssquare = 0x33ae;
  t.radoverssquaredsquare = 0x33af;
  t.radsquare = 0x33ad;
  t.rafe = 0x05bf;
  t.rafehebrew = 0x05bf;
  t.ragujarati = 0x0ab0;
  t.ragurmukhi = 0x0a30;
  t.rahiragana = 0x3089;
  t.rakatakana = 0x30e9;
  t.rakatakanahalfwidth = 0xff97;
  t.ralowerdiagonalbengali = 0x09f1;
  t.ramiddlediagonalbengali = 0x09f0;
  t.ramshorn = 0x0264;
  t.ratio = 0x2236;
  t.rbopomofo = 0x3116;
  t.rcaron = 0x0159;
  t.rcedilla = 0x0157;
  t.rcircle = 0x24e1;
  t.rcommaaccent = 0x0157;
  t.rdblgrave = 0x0211;
  t.rdotaccent = 0x1e59;
  t.rdotbelow = 0x1e5b;
  t.rdotbelowmacron = 0x1e5d;
  t.referencemark = 0x203b;
  t.reflexsubset = 0x2286;
  t.reflexsuperset = 0x2287;
  t.registered = 0x00ae;
  t.registersans = 0xf8e8;
  t.registerserif = 0xf6da;
  t.reharabic = 0x0631;
  t.reharmenian = 0x0580;
  t.rehfinalarabic = 0xfeae;
  t.rehiragana = 0x308c;
  t.rekatakana = 0x30ec;
  t.rekatakanahalfwidth = 0xff9a;
  t.resh = 0x05e8;
  t.reshdageshhebrew = 0xfb48;
  t.reshhebrew = 0x05e8;
  t.reversedtilde = 0x223d;
  t.reviahebrew = 0x0597;
  t.reviamugrashhebrew = 0x0597;
  t.revlogicalnot = 0x2310;
  t.rfishhook = 0x027e;
  t.rfishhookreversed = 0x027f;
  t.rhabengali = 0x09dd;
  t.rhadeva = 0x095d;
  t.rho = 0x03c1;
  t.rhook = 0x027d;
  t.rhookturned = 0x027b;
  t.rhookturnedsuperior = 0x02b5;
  t.rhosymbolgreek = 0x03f1;
  t.rhotichookmod = 0x02de;
  t.rieulacirclekorean = 0x3271;
  t.rieulaparenkorean = 0x3211;
  t.rieulcirclekorean = 0x3263;
  t.rieulhieuhkorean = 0x3140;
  t.rieulkiyeokkorean = 0x313a;
  t.rieulkiyeoksioskorean = 0x3169;
  t.rieulkorean = 0x3139;
  t.rieulmieumkorean = 0x313b;
  t.rieulpansioskorean = 0x316c;
  t.rieulparenkorean = 0x3203;
  t.rieulphieuphkorean = 0x313f;
  t.rieulpieupkorean = 0x313c;
  t.rieulpieupsioskorean = 0x316b;
  t.rieulsioskorean = 0x313d;
  t.rieulthieuthkorean = 0x313e;
  t.rieultikeutkorean = 0x316a;
  t.rieulyeorinhieuhkorean = 0x316d;
  t.rightangle = 0x221f;
  t.righttackbelowcmb = 0x0319;
  t.righttriangle = 0x22bf;
  t.rihiragana = 0x308a;
  t.rikatakana = 0x30ea;
  t.rikatakanahalfwidth = 0xff98;
  t.ring = 0x02da;
  t.ringbelowcmb = 0x0325;
  t.ringcmb = 0x030a;
  t.ringhalfleft = 0x02bf;
  t.ringhalfleftarmenian = 0x0559;
  t.ringhalfleftbelowcmb = 0x031c;
  t.ringhalfleftcentered = 0x02d3;
  t.ringhalfright = 0x02be;
  t.ringhalfrightbelowcmb = 0x0339;
  t.ringhalfrightcentered = 0x02d2;
  t.rinvertedbreve = 0x0213;
  t.rittorusquare = 0x3351;
  t.rlinebelow = 0x1e5f;
  t.rlongleg = 0x027c;
  t.rlonglegturned = 0x027a;
  t.rmonospace = 0xff52;
  t.rohiragana = 0x308d;
  t.rokatakana = 0x30ed;
  t.rokatakanahalfwidth = 0xff9b;
  t.roruathai = 0x0e23;
  t.rparen = 0x24ad;
  t.rrabengali = 0x09dc;
  t.rradeva = 0x0931;
  t.rragurmukhi = 0x0a5c;
  t.rreharabic = 0x0691;
  t.rrehfinalarabic = 0xfb8d;
  t.rrvocalicbengali = 0x09e0;
  t.rrvocalicdeva = 0x0960;
  t.rrvocalicgujarati = 0x0ae0;
  t.rrvocalicvowelsignbengali = 0x09c4;
  t.rrvocalicvowelsigndeva = 0x0944;
  t.rrvocalicvowelsigngujarati = 0x0ac4;
  t.rsuperior = 0xf6f1;
  t.rtblock = 0x2590;
  t.rturned = 0x0279;
  t.rturnedsuperior = 0x02b4;
  t.ruhiragana = 0x308b;
  t.rukatakana = 0x30eb;
  t.rukatakanahalfwidth = 0xff99;
  t.rupeemarkbengali = 0x09f2;
  t.rupeesignbengali = 0x09f3;
  t.rupiah = 0xf6dd;
  t.ruthai = 0x0e24;
  t.rvocalicbengali = 0x098b;
  t.rvocalicdeva = 0x090b;
  t.rvocalicgujarati = 0x0a8b;
  t.rvocalicvowelsignbengali = 0x09c3;
  t.rvocalicvowelsigndeva = 0x0943;
  t.rvocalicvowelsigngujarati = 0x0ac3;
  t.s = 0x0073;
  t.sabengali = 0x09b8;
  t.sacute = 0x015b;
  t.sacutedotaccent = 0x1e65;
  t.sadarabic = 0x0635;
  t.sadeva = 0x0938;
  t.sadfinalarabic = 0xfeba;
  t.sadinitialarabic = 0xfebb;
  t.sadmedialarabic = 0xfebc;
  t.sagujarati = 0x0ab8;
  t.sagurmukhi = 0x0a38;
  t.sahiragana = 0x3055;
  t.sakatakana = 0x30b5;
  t.sakatakanahalfwidth = 0xff7b;
  t.sallallahoualayhewasallamarabic = 0xfdfa;
  t.samekh = 0x05e1;
  t.samekhdagesh = 0xfb41;
  t.samekhdageshhebrew = 0xfb41;
  t.samekhhebrew = 0x05e1;
  t.saraaathai = 0x0e32;
  t.saraaethai = 0x0e41;
  t.saraaimaimalaithai = 0x0e44;
  t.saraaimaimuanthai = 0x0e43;
  t.saraamthai = 0x0e33;
  t.saraathai = 0x0e30;
  t.saraethai = 0x0e40;
  t.saraiileftthai = 0xf886;
  t.saraiithai = 0x0e35;
  t.saraileftthai = 0xf885;
  t.saraithai = 0x0e34;
  t.saraothai = 0x0e42;
  t.saraueeleftthai = 0xf888;
  t.saraueethai = 0x0e37;
  t.saraueleftthai = 0xf887;
  t.sarauethai = 0x0e36;
  t.sarauthai = 0x0e38;
  t.sarauuthai = 0x0e39;
  t.sbopomofo = 0x3119;
  t.scaron = 0x0161;
  t.scarondotaccent = 0x1e67;
  t.scedilla = 0x015f;
  t.schwa = 0x0259;
  t.schwacyrillic = 0x04d9;
  t.schwadieresiscyrillic = 0x04db;
  t.schwahook = 0x025a;
  t.scircle = 0x24e2;
  t.scircumflex = 0x015d;
  t.scommaaccent = 0x0219;
  t.sdotaccent = 0x1e61;
  t.sdotbelow = 0x1e63;
  t.sdotbelowdotaccent = 0x1e69;
  t.seagullbelowcmb = 0x033c;
  t.second = 0x2033;
  t.secondtonechinese = 0x02ca;
  t.section = 0x00a7;
  t.seenarabic = 0x0633;
  t.seenfinalarabic = 0xfeb2;
  t.seeninitialarabic = 0xfeb3;
  t.seenmedialarabic = 0xfeb4;
  t.segol = 0x05b6;
  t.segol13 = 0x05b6;
  t.segol1f = 0x05b6;
  t.segol2c = 0x05b6;
  t.segolhebrew = 0x05b6;
  t.segolnarrowhebrew = 0x05b6;
  t.segolquarterhebrew = 0x05b6;
  t.segoltahebrew = 0x0592;
  t.segolwidehebrew = 0x05b6;
  t.seharmenian = 0x057d;
  t.sehiragana = 0x305b;
  t.sekatakana = 0x30bb;
  t.sekatakanahalfwidth = 0xff7e;
  t.semicolon = 0x003b;
  t.semicolonarabic = 0x061b;
  t.semicolonmonospace = 0xff1b;
  t.semicolonsmall = 0xfe54;
  t.semivoicedmarkkana = 0x309c;
  t.semivoicedmarkkanahalfwidth = 0xff9f;
  t.sentisquare = 0x3322;
  t.sentosquare = 0x3323;
  t.seven = 0x0037;
  t.sevenarabic = 0x0667;
  t.sevenbengali = 0x09ed;
  t.sevencircle = 0x2466;
  t.sevencircleinversesansserif = 0x2790;
  t.sevendeva = 0x096d;
  t.seveneighths = 0x215e;
  t.sevengujarati = 0x0aed;
  t.sevengurmukhi = 0x0a6d;
  t.sevenhackarabic = 0x0667;
  t.sevenhangzhou = 0x3027;
  t.sevenideographicparen = 0x3226;
  t.seveninferior = 0x2087;
  t.sevenmonospace = 0xff17;
  t.sevenoldstyle = 0xf737;
  t.sevenparen = 0x247a;
  t.sevenperiod = 0x248e;
  t.sevenpersian = 0x06f7;
  t.sevenroman = 0x2176;
  t.sevensuperior = 0x2077;
  t.seventeencircle = 0x2470;
  t.seventeenparen = 0x2484;
  t.seventeenperiod = 0x2498;
  t.seventhai = 0x0e57;
  t.sfthyphen = 0x00ad;
  t.shaarmenian = 0x0577;
  t.shabengali = 0x09b6;
  t.shacyrillic = 0x0448;
  t.shaddaarabic = 0x0651;
  t.shaddadammaarabic = 0xfc61;
  t.shaddadammatanarabic = 0xfc5e;
  t.shaddafathaarabic = 0xfc60;
  t.shaddakasraarabic = 0xfc62;
  t.shaddakasratanarabic = 0xfc5f;
  t.shade = 0x2592;
  t.shadedark = 0x2593;
  t.shadelight = 0x2591;
  t.shademedium = 0x2592;
  t.shadeva = 0x0936;
  t.shagujarati = 0x0ab6;
  t.shagurmukhi = 0x0a36;
  t.shalshelethebrew = 0x0593;
  t.shbopomofo = 0x3115;
  t.shchacyrillic = 0x0449;
  t.sheenarabic = 0x0634;
  t.sheenfinalarabic = 0xfeb6;
  t.sheeninitialarabic = 0xfeb7;
  t.sheenmedialarabic = 0xfeb8;
  t.sheicoptic = 0x03e3;
  t.sheqel = 0x20aa;
  t.sheqelhebrew = 0x20aa;
  t.sheva = 0x05b0;
  t.sheva115 = 0x05b0;
  t.sheva15 = 0x05b0;
  t.sheva22 = 0x05b0;
  t.sheva2e = 0x05b0;
  t.shevahebrew = 0x05b0;
  t.shevanarrowhebrew = 0x05b0;
  t.shevaquarterhebrew = 0x05b0;
  t.shevawidehebrew = 0x05b0;
  t.shhacyrillic = 0x04bb;
  t.shimacoptic = 0x03ed;
  t.shin = 0x05e9;
  t.shindagesh = 0xfb49;
  t.shindageshhebrew = 0xfb49;
  t.shindageshshindot = 0xfb2c;
  t.shindageshshindothebrew = 0xfb2c;
  t.shindageshsindot = 0xfb2d;
  t.shindageshsindothebrew = 0xfb2d;
  t.shindothebrew = 0x05c1;
  t.shinhebrew = 0x05e9;
  t.shinshindot = 0xfb2a;
  t.shinshindothebrew = 0xfb2a;
  t.shinsindot = 0xfb2b;
  t.shinsindothebrew = 0xfb2b;
  t.shook = 0x0282;
  t.sigma = 0x03c3;
  t.sigma1 = 0x03c2;
  t.sigmafinal = 0x03c2;
  t.sigmalunatesymbolgreek = 0x03f2;
  t.sihiragana = 0x3057;
  t.sikatakana = 0x30b7;
  t.sikatakanahalfwidth = 0xff7c;
  t.siluqhebrew = 0x05bd;
  t.siluqlefthebrew = 0x05bd;
  t.similar = 0x223c;
  t.sindothebrew = 0x05c2;
  t.siosacirclekorean = 0x3274;
  t.siosaparenkorean = 0x3214;
  t.sioscieuckorean = 0x317e;
  t.sioscirclekorean = 0x3266;
  t.sioskiyeokkorean = 0x317a;
  t.sioskorean = 0x3145;
  t.siosnieunkorean = 0x317b;
  t.siosparenkorean = 0x3206;
  t.siospieupkorean = 0x317d;
  t.siostikeutkorean = 0x317c;
  t.six = 0x0036;
  t.sixarabic = 0x0666;
  t.sixbengali = 0x09ec;
  t.sixcircle = 0x2465;
  t.sixcircleinversesansserif = 0x278f;
  t.sixdeva = 0x096c;
  t.sixgujarati = 0x0aec;
  t.sixgurmukhi = 0x0a6c;
  t.sixhackarabic = 0x0666;
  t.sixhangzhou = 0x3026;
  t.sixideographicparen = 0x3225;
  t.sixinferior = 0x2086;
  t.sixmonospace = 0xff16;
  t.sixoldstyle = 0xf736;
  t.sixparen = 0x2479;
  t.sixperiod = 0x248d;
  t.sixpersian = 0x06f6;
  t.sixroman = 0x2175;
  t.sixsuperior = 0x2076;
  t.sixteencircle = 0x246f;
  t.sixteencurrencydenominatorbengali = 0x09f9;
  t.sixteenparen = 0x2483;
  t.sixteenperiod = 0x2497;
  t.sixthai = 0x0e56;
  t.slash = 0x002f;
  t.slashmonospace = 0xff0f;
  t.slong = 0x017f;
  t.slongdotaccent = 0x1e9b;
  t.smileface = 0x263a;
  t.smonospace = 0xff53;
  t.sofpasuqhebrew = 0x05c3;
  t.softhyphen = 0x00ad;
  t.softsigncyrillic = 0x044c;
  t.sohiragana = 0x305d;
  t.sokatakana = 0x30bd;
  t.sokatakanahalfwidth = 0xff7f;
  t.soliduslongoverlaycmb = 0x0338;
  t.solidusshortoverlaycmb = 0x0337;
  t.sorusithai = 0x0e29;
  t.sosalathai = 0x0e28;
  t.sosothai = 0x0e0b;
  t.sosuathai = 0x0e2a;
  t.space = 0x0020;
  t.spacehackarabic = 0x0020;
  t.spade = 0x2660;
  t.spadesuitblack = 0x2660;
  t.spadesuitwhite = 0x2664;
  t.sparen = 0x24ae;
  t.squarebelowcmb = 0x033b;
  t.squarecc = 0x33c4;
  t.squarecm = 0x339d;
  t.squarediagonalcrosshatchfill = 0x25a9;
  t.squarehorizontalfill = 0x25a4;
  t.squarekg = 0x338f;
  t.squarekm = 0x339e;
  t.squarekmcapital = 0x33ce;
  t.squareln = 0x33d1;
  t.squarelog = 0x33d2;
  t.squaremg = 0x338e;
  t.squaremil = 0x33d5;
  t.squaremm = 0x339c;
  t.squaremsquared = 0x33a1;
  t.squareorthogonalcrosshatchfill = 0x25a6;
  t.squareupperlefttolowerrightfill = 0x25a7;
  t.squareupperrighttolowerleftfill = 0x25a8;
  t.squareverticalfill = 0x25a5;
  t.squarewhitewithsmallblack = 0x25a3;
  t.srsquare = 0x33db;
  t.ssabengali = 0x09b7;
  t.ssadeva = 0x0937;
  t.ssagujarati = 0x0ab7;
  t.ssangcieuckorean = 0x3149;
  t.ssanghieuhkorean = 0x3185;
  t.ssangieungkorean = 0x3180;
  t.ssangkiyeokkorean = 0x3132;
  t.ssangnieunkorean = 0x3165;
  t.ssangpieupkorean = 0x3143;
  t.ssangsioskorean = 0x3146;
  t.ssangtikeutkorean = 0x3138;
  t.ssuperior = 0xf6f2;
  t.sterling = 0x00a3;
  t.sterlingmonospace = 0xffe1;
  t.strokelongoverlaycmb = 0x0336;
  t.strokeshortoverlaycmb = 0x0335;
  t.subset = 0x2282;
  t.subsetnotequal = 0x228a;
  t.subsetorequal = 0x2286;
  t.succeeds = 0x227b;
  t.suchthat = 0x220b;
  t.suhiragana = 0x3059;
  t.sukatakana = 0x30b9;
  t.sukatakanahalfwidth = 0xff7d;
  t.sukunarabic = 0x0652;
  t.summation = 0x2211;
  t.sun = 0x263c;
  t.superset = 0x2283;
  t.supersetnotequal = 0x228b;
  t.supersetorequal = 0x2287;
  t.svsquare = 0x33dc;
  t.syouwaerasquare = 0x337c;
  t.t = 0x0074;
  t.tabengali = 0x09a4;
  t.tackdown = 0x22a4;
  t.tackleft = 0x22a3;
  t.tadeva = 0x0924;
  t.tagujarati = 0x0aa4;
  t.tagurmukhi = 0x0a24;
  t.taharabic = 0x0637;
  t.tahfinalarabic = 0xfec2;
  t.tahinitialarabic = 0xfec3;
  t.tahiragana = 0x305f;
  t.tahmedialarabic = 0xfec4;
  t.taisyouerasquare = 0x337d;
  t.takatakana = 0x30bf;
  t.takatakanahalfwidth = 0xff80;
  t.tatweelarabic = 0x0640;
  t.tau = 0x03c4;
  t.tav = 0x05ea;
  t.tavdages = 0xfb4a;
  t.tavdagesh = 0xfb4a;
  t.tavdageshhebrew = 0xfb4a;
  t.tavhebrew = 0x05ea;
  t.tbar = 0x0167;
  t.tbopomofo = 0x310a;
  t.tcaron = 0x0165;
  t.tccurl = 0x02a8;
  t.tcedilla = 0x0163;
  t.tcheharabic = 0x0686;
  t.tchehfinalarabic = 0xfb7b;
  t.tchehinitialarabic = 0xfb7c;
  t.tchehmedialarabic = 0xfb7d;
  t.tcircle = 0x24e3;
  t.tcircumflexbelow = 0x1e71;
  t.tcommaaccent = 0x0163;
  t.tdieresis = 0x1e97;
  t.tdotaccent = 0x1e6b;
  t.tdotbelow = 0x1e6d;
  t.tecyrillic = 0x0442;
  t.tedescendercyrillic = 0x04ad;
  t.teharabic = 0x062a;
  t.tehfinalarabic = 0xfe96;
  t.tehhahinitialarabic = 0xfca2;
  t.tehhahisolatedarabic = 0xfc0c;
  t.tehinitialarabic = 0xfe97;
  t.tehiragana = 0x3066;
  t.tehjeeminitialarabic = 0xfca1;
  t.tehjeemisolatedarabic = 0xfc0b;
  t.tehmarbutaarabic = 0x0629;
  t.tehmarbutafinalarabic = 0xfe94;
  t.tehmedialarabic = 0xfe98;
  t.tehmeeminitialarabic = 0xfca4;
  t.tehmeemisolatedarabic = 0xfc0e;
  t.tehnoonfinalarabic = 0xfc73;
  t.tekatakana = 0x30c6;
  t.tekatakanahalfwidth = 0xff83;
  t.telephone = 0x2121;
  t.telephoneblack = 0x260e;
  t.telishagedolahebrew = 0x05a0;
  t.telishaqetanahebrew = 0x05a9;
  t.tencircle = 0x2469;
  t.tenideographicparen = 0x3229;
  t.tenparen = 0x247d;
  t.tenperiod = 0x2491;
  t.tenroman = 0x2179;
  t.tesh = 0x02a7;
  t.tet = 0x05d8;
  t.tetdagesh = 0xfb38;
  t.tetdageshhebrew = 0xfb38;
  t.tethebrew = 0x05d8;
  t.tetsecyrillic = 0x04b5;
  t.tevirhebrew = 0x059b;
  t.tevirlefthebrew = 0x059b;
  t.thabengali = 0x09a5;
  t.thadeva = 0x0925;
  t.thagujarati = 0x0aa5;
  t.thagurmukhi = 0x0a25;
  t.thalarabic = 0x0630;
  t.thalfinalarabic = 0xfeac;
  t.thanthakhatlowleftthai = 0xf898;
  t.thanthakhatlowrightthai = 0xf897;
  t.thanthakhatthai = 0x0e4c;
  t.thanthakhatupperleftthai = 0xf896;
  t.theharabic = 0x062b;
  t.thehfinalarabic = 0xfe9a;
  t.thehinitialarabic = 0xfe9b;
  t.thehmedialarabic = 0xfe9c;
  t.thereexists = 0x2203;
  t.therefore = 0x2234;
  t.theta = 0x03b8;
  t.theta1 = 0x03d1;
  t.thetasymbolgreek = 0x03d1;
  t.thieuthacirclekorean = 0x3279;
  t.thieuthaparenkorean = 0x3219;
  t.thieuthcirclekorean = 0x326b;
  t.thieuthkorean = 0x314c;
  t.thieuthparenkorean = 0x320b;
  t.thirteencircle = 0x246c;
  t.thirteenparen = 0x2480;
  t.thirteenperiod = 0x2494;
  t.thonangmonthothai = 0x0e11;
  t.thook = 0x01ad;
  t.thophuthaothai = 0x0e12;
  t.thorn = 0x00fe;
  t.thothahanthai = 0x0e17;
  t.thothanthai = 0x0e10;
  t.thothongthai = 0x0e18;
  t.thothungthai = 0x0e16;
  t.thousandcyrillic = 0x0482;
  t.thousandsseparatorarabic = 0x066c;
  t.thousandsseparatorpersian = 0x066c;
  t.three = 0x0033;
  t.threearabic = 0x0663;
  t.threebengali = 0x09e9;
  t.threecircle = 0x2462;
  t.threecircleinversesansserif = 0x278c;
  t.threedeva = 0x0969;
  t.threeeighths = 0x215c;
  t.threegujarati = 0x0ae9;
  t.threegurmukhi = 0x0a69;
  t.threehackarabic = 0x0663;
  t.threehangzhou = 0x3023;
  t.threeideographicparen = 0x3222;
  t.threeinferior = 0x2083;
  t.threemonospace = 0xff13;
  t.threenumeratorbengali = 0x09f6;
  t.threeoldstyle = 0xf733;
  t.threeparen = 0x2476;
  t.threeperiod = 0x248a;
  t.threepersian = 0x06f3;
  t.threequarters = 0x00be;
  t.threequartersemdash = 0xf6de;
  t.threeroman = 0x2172;
  t.threesuperior = 0x00b3;
  t.threethai = 0x0e53;
  t.thzsquare = 0x3394;
  t.tihiragana = 0x3061;
  t.tikatakana = 0x30c1;
  t.tikatakanahalfwidth = 0xff81;
  t.tikeutacirclekorean = 0x3270;
  t.tikeutaparenkorean = 0x3210;
  t.tikeutcirclekorean = 0x3262;
  t.tikeutkorean = 0x3137;
  t.tikeutparenkorean = 0x3202;
  t.tilde = 0x02dc;
  t.tildebelowcmb = 0x0330;
  t.tildecmb = 0x0303;
  t.tildecomb = 0x0303;
  t.tildedoublecmb = 0x0360;
  t.tildeoperator = 0x223c;
  t.tildeoverlaycmb = 0x0334;
  t.tildeverticalcmb = 0x033e;
  t.timescircle = 0x2297;
  t.tipehahebrew = 0x0596;
  t.tipehalefthebrew = 0x0596;
  t.tippigurmukhi = 0x0a70;
  t.titlocyrilliccmb = 0x0483;
  t.tiwnarmenian = 0x057f;
  t.tlinebelow = 0x1e6f;
  t.tmonospace = 0xff54;
  t.toarmenian = 0x0569;
  t.tohiragana = 0x3068;
  t.tokatakana = 0x30c8;
  t.tokatakanahalfwidth = 0xff84;
  t.tonebarextrahighmod = 0x02e5;
  t.tonebarextralowmod = 0x02e9;
  t.tonebarhighmod = 0x02e6;
  t.tonebarlowmod = 0x02e8;
  t.tonebarmidmod = 0x02e7;
  t.tonefive = 0x01bd;
  t.tonesix = 0x0185;
  t.tonetwo = 0x01a8;
  t.tonos = 0x0384;
  t.tonsquare = 0x3327;
  t.topatakthai = 0x0e0f;
  t.tortoiseshellbracketleft = 0x3014;
  t.tortoiseshellbracketleftsmall = 0xfe5d;
  t.tortoiseshellbracketleftvertical = 0xfe39;
  t.tortoiseshellbracketright = 0x3015;
  t.tortoiseshellbracketrightsmall = 0xfe5e;
  t.tortoiseshellbracketrightvertical = 0xfe3a;
  t.totaothai = 0x0e15;
  t.tpalatalhook = 0x01ab;
  t.tparen = 0x24af;
  t.trademark = 0x2122;
  t.trademarksans = 0xf8ea;
  t.trademarkserif = 0xf6db;
  t.tretroflexhook = 0x0288;
  t.triagdn = 0x25bc;
  t.triaglf = 0x25c4;
  t.triagrt = 0x25ba;
  t.triagup = 0x25b2;
  t.ts = 0x02a6;
  t.tsadi = 0x05e6;
  t.tsadidagesh = 0xfb46;
  t.tsadidageshhebrew = 0xfb46;
  t.tsadihebrew = 0x05e6;
  t.tsecyrillic = 0x0446;
  t.tsere = 0x05b5;
  t.tsere12 = 0x05b5;
  t.tsere1e = 0x05b5;
  t.tsere2b = 0x05b5;
  t.tserehebrew = 0x05b5;
  t.tserenarrowhebrew = 0x05b5;
  t.tserequarterhebrew = 0x05b5;
  t.tserewidehebrew = 0x05b5;
  t.tshecyrillic = 0x045b;
  t.tsuperior = 0xf6f3;
  t.ttabengali = 0x099f;
  t.ttadeva = 0x091f;
  t.ttagujarati = 0x0a9f;
  t.ttagurmukhi = 0x0a1f;
  t.tteharabic = 0x0679;
  t.ttehfinalarabic = 0xfb67;
  t.ttehinitialarabic = 0xfb68;
  t.ttehmedialarabic = 0xfb69;
  t.tthabengali = 0x09a0;
  t.tthadeva = 0x0920;
  t.tthagujarati = 0x0aa0;
  t.tthagurmukhi = 0x0a20;
  t.tturned = 0x0287;
  t.tuhiragana = 0x3064;
  t.tukatakana = 0x30c4;
  t.tukatakanahalfwidth = 0xff82;
  t.tusmallhiragana = 0x3063;
  t.tusmallkatakana = 0x30c3;
  t.tusmallkatakanahalfwidth = 0xff6f;
  t.twelvecircle = 0x246b;
  t.twelveparen = 0x247f;
  t.twelveperiod = 0x2493;
  t.twelveroman = 0x217b;
  t.twentycircle = 0x2473;
  t.twentyhangzhou = 0x5344;
  t.twentyparen = 0x2487;
  t.twentyperiod = 0x249b;
  t.two = 0x0032;
  t.twoarabic = 0x0662;
  t.twobengali = 0x09e8;
  t.twocircle = 0x2461;
  t.twocircleinversesansserif = 0x278b;
  t.twodeva = 0x0968;
  t.twodotenleader = 0x2025;
  t.twodotleader = 0x2025;
  t.twodotleadervertical = 0xfe30;
  t.twogujarati = 0x0ae8;
  t.twogurmukhi = 0x0a68;
  t.twohackarabic = 0x0662;
  t.twohangzhou = 0x3022;
  t.twoideographicparen = 0x3221;
  t.twoinferior = 0x2082;
  t.twomonospace = 0xff12;
  t.twonumeratorbengali = 0x09f5;
  t.twooldstyle = 0xf732;
  t.twoparen = 0x2475;
  t.twoperiod = 0x2489;
  t.twopersian = 0x06f2;
  t.tworoman = 0x2171;
  t.twostroke = 0x01bb;
  t.twosuperior = 0x00b2;
  t.twothai = 0x0e52;
  t.twothirds = 0x2154;
  t.u = 0x0075;
  t.uacute = 0x00fa;
  t.ubar = 0x0289;
  t.ubengali = 0x0989;
  t.ubopomofo = 0x3128;
  t.ubreve = 0x016d;
  t.ucaron = 0x01d4;
  t.ucircle = 0x24e4;
  t.ucircumflex = 0x00fb;
  t.ucircumflexbelow = 0x1e77;
  t.ucyrillic = 0x0443;
  t.udattadeva = 0x0951;
  t.udblacute = 0x0171;
  t.udblgrave = 0x0215;
  t.udeva = 0x0909;
  t.udieresis = 0x00fc;
  t.udieresisacute = 0x01d8;
  t.udieresisbelow = 0x1e73;
  t.udieresiscaron = 0x01da;
  t.udieresiscyrillic = 0x04f1;
  t.udieresisgrave = 0x01dc;
  t.udieresismacron = 0x01d6;
  t.udotbelow = 0x1ee5;
  t.ugrave = 0x00f9;
  t.ugujarati = 0x0a89;
  t.ugurmukhi = 0x0a09;
  t.uhiragana = 0x3046;
  t.uhookabove = 0x1ee7;
  t.uhorn = 0x01b0;
  t.uhornacute = 0x1ee9;
  t.uhorndotbelow = 0x1ef1;
  t.uhorngrave = 0x1eeb;
  t.uhornhookabove = 0x1eed;
  t.uhorntilde = 0x1eef;
  t.uhungarumlaut = 0x0171;
  t.uhungarumlautcyrillic = 0x04f3;
  t.uinvertedbreve = 0x0217;
  t.ukatakana = 0x30a6;
  t.ukatakanahalfwidth = 0xff73;
  t.ukcyrillic = 0x0479;
  t.ukorean = 0x315c;
  t.umacron = 0x016b;
  t.umacroncyrillic = 0x04ef;
  t.umacrondieresis = 0x1e7b;
  t.umatragurmukhi = 0x0a41;
  t.umonospace = 0xff55;
  t.underscore = 0x005f;
  t.underscoredbl = 0x2017;
  t.underscoremonospace = 0xff3f;
  t.underscorevertical = 0xfe33;
  t.underscorewavy = 0xfe4f;
  t.union = 0x222a;
  t.universal = 0x2200;
  t.uogonek = 0x0173;
  t.uparen = 0x24b0;
  t.upblock = 0x2580;
  t.upperdothebrew = 0x05c4;
  t.upsilon = 0x03c5;
  t.upsilondieresis = 0x03cb;
  t.upsilondieresistonos = 0x03b0;
  t.upsilonlatin = 0x028a;
  t.upsilontonos = 0x03cd;
  t.uptackbelowcmb = 0x031d;
  t.uptackmod = 0x02d4;
  t.uragurmukhi = 0x0a73;
  t.uring = 0x016f;
  t.ushortcyrillic = 0x045e;
  t.usmallhiragana = 0x3045;
  t.usmallkatakana = 0x30a5;
  t.usmallkatakanahalfwidth = 0xff69;
  t.ustraightcyrillic = 0x04af;
  t.ustraightstrokecyrillic = 0x04b1;
  t.utilde = 0x0169;
  t.utildeacute = 0x1e79;
  t.utildebelow = 0x1e75;
  t.uubengali = 0x098a;
  t.uudeva = 0x090a;
  t.uugujarati = 0x0a8a;
  t.uugurmukhi = 0x0a0a;
  t.uumatragurmukhi = 0x0a42;
  t.uuvowelsignbengali = 0x09c2;
  t.uuvowelsigndeva = 0x0942;
  t.uuvowelsigngujarati = 0x0ac2;
  t.uvowelsignbengali = 0x09c1;
  t.uvowelsigndeva = 0x0941;
  t.uvowelsigngujarati = 0x0ac1;
  t.v = 0x0076;
  t.vadeva = 0x0935;
  t.vagujarati = 0x0ab5;
  t.vagurmukhi = 0x0a35;
  t.vakatakana = 0x30f7;
  t.vav = 0x05d5;
  t.vavdagesh = 0xfb35;
  t.vavdagesh65 = 0xfb35;
  t.vavdageshhebrew = 0xfb35;
  t.vavhebrew = 0x05d5;
  t.vavholam = 0xfb4b;
  t.vavholamhebrew = 0xfb4b;
  t.vavvavhebrew = 0x05f0;
  t.vavyodhebrew = 0x05f1;
  t.vcircle = 0x24e5;
  t.vdotbelow = 0x1e7f;
  t.vecyrillic = 0x0432;
  t.veharabic = 0x06a4;
  t.vehfinalarabic = 0xfb6b;
  t.vehinitialarabic = 0xfb6c;
  t.vehmedialarabic = 0xfb6d;
  t.vekatakana = 0x30f9;
  t.venus = 0x2640;
  t.verticalbar = 0x007c;
  t.verticallineabovecmb = 0x030d;
  t.verticallinebelowcmb = 0x0329;
  t.verticallinelowmod = 0x02cc;
  t.verticallinemod = 0x02c8;
  t.vewarmenian = 0x057e;
  t.vhook = 0x028b;
  t.vikatakana = 0x30f8;
  t.viramabengali = 0x09cd;
  t.viramadeva = 0x094d;
  t.viramagujarati = 0x0acd;
  t.visargabengali = 0x0983;
  t.visargadeva = 0x0903;
  t.visargagujarati = 0x0a83;
  t.vmonospace = 0xff56;
  t.voarmenian = 0x0578;
  t.voicediterationhiragana = 0x309e;
  t.voicediterationkatakana = 0x30fe;
  t.voicedmarkkana = 0x309b;
  t.voicedmarkkanahalfwidth = 0xff9e;
  t.vokatakana = 0x30fa;
  t.vparen = 0x24b1;
  t.vtilde = 0x1e7d;
  t.vturned = 0x028c;
  t.vuhiragana = 0x3094;
  t.vukatakana = 0x30f4;
  t.w = 0x0077;
  t.wacute = 0x1e83;
  t.waekorean = 0x3159;
  t.wahiragana = 0x308f;
  t.wakatakana = 0x30ef;
  t.wakatakanahalfwidth = 0xff9c;
  t.wakorean = 0x3158;
  t.wasmallhiragana = 0x308e;
  t.wasmallkatakana = 0x30ee;
  t.wattosquare = 0x3357;
  t.wavedash = 0x301c;
  t.wavyunderscorevertical = 0xfe34;
  t.wawarabic = 0x0648;
  t.wawfinalarabic = 0xfeee;
  t.wawhamzaabovearabic = 0x0624;
  t.wawhamzaabovefinalarabic = 0xfe86;
  t.wbsquare = 0x33dd;
  t.wcircle = 0x24e6;
  t.wcircumflex = 0x0175;
  t.wdieresis = 0x1e85;
  t.wdotaccent = 0x1e87;
  t.wdotbelow = 0x1e89;
  t.wehiragana = 0x3091;
  t.weierstrass = 0x2118;
  t.wekatakana = 0x30f1;
  t.wekorean = 0x315e;
  t.weokorean = 0x315d;
  t.wgrave = 0x1e81;
  t.whitebullet = 0x25e6;
  t.whitecircle = 0x25cb;
  t.whitecircleinverse = 0x25d9;
  t.whitecornerbracketleft = 0x300e;
  t.whitecornerbracketleftvertical = 0xfe43;
  t.whitecornerbracketright = 0x300f;
  t.whitecornerbracketrightvertical = 0xfe44;
  t.whitediamond = 0x25c7;
  t.whitediamondcontainingblacksmalldiamond = 0x25c8;
  t.whitedownpointingsmalltriangle = 0x25bf;
  t.whitedownpointingtriangle = 0x25bd;
  t.whiteleftpointingsmalltriangle = 0x25c3;
  t.whiteleftpointingtriangle = 0x25c1;
  t.whitelenticularbracketleft = 0x3016;
  t.whitelenticularbracketright = 0x3017;
  t.whiterightpointingsmalltriangle = 0x25b9;
  t.whiterightpointingtriangle = 0x25b7;
  t.whitesmallsquare = 0x25ab;
  t.whitesmilingface = 0x263a;
  t.whitesquare = 0x25a1;
  t.whitestar = 0x2606;
  t.whitetelephone = 0x260f;
  t.whitetortoiseshellbracketleft = 0x3018;
  t.whitetortoiseshellbracketright = 0x3019;
  t.whiteuppointingsmalltriangle = 0x25b5;
  t.whiteuppointingtriangle = 0x25b3;
  t.wihiragana = 0x3090;
  t.wikatakana = 0x30f0;
  t.wikorean = 0x315f;
  t.wmonospace = 0xff57;
  t.wohiragana = 0x3092;
  t.wokatakana = 0x30f2;
  t.wokatakanahalfwidth = 0xff66;
  t.won = 0x20a9;
  t.wonmonospace = 0xffe6;
  t.wowaenthai = 0x0e27;
  t.wparen = 0x24b2;
  t.wring = 0x1e98;
  t.wsuperior = 0x02b7;
  t.wturned = 0x028d;
  t.wynn = 0x01bf;
  t.x = 0x0078;
  t.xabovecmb = 0x033d;
  t.xbopomofo = 0x3112;
  t.xcircle = 0x24e7;
  t.xdieresis = 0x1e8d;
  t.xdotaccent = 0x1e8b;
  t.xeharmenian = 0x056d;
  t.xi = 0x03be;
  t.xmonospace = 0xff58;
  t.xparen = 0x24b3;
  t.xsuperior = 0x02e3;
  t.y = 0x0079;
  t.yaadosquare = 0x334e;
  t.yabengali = 0x09af;
  t.yacute = 0x00fd;
  t.yadeva = 0x092f;
  t.yaekorean = 0x3152;
  t.yagujarati = 0x0aaf;
  t.yagurmukhi = 0x0a2f;
  t.yahiragana = 0x3084;
  t.yakatakana = 0x30e4;
  t.yakatakanahalfwidth = 0xff94;
  t.yakorean = 0x3151;
  t.yamakkanthai = 0x0e4e;
  t.yasmallhiragana = 0x3083;
  t.yasmallkatakana = 0x30e3;
  t.yasmallkatakanahalfwidth = 0xff6c;
  t.yatcyrillic = 0x0463;
  t.ycircle = 0x24e8;
  t.ycircumflex = 0x0177;
  t.ydieresis = 0x00ff;
  t.ydotaccent = 0x1e8f;
  t.ydotbelow = 0x1ef5;
  t.yeharabic = 0x064a;
  t.yehbarreearabic = 0x06d2;
  t.yehbarreefinalarabic = 0xfbaf;
  t.yehfinalarabic = 0xfef2;
  t.yehhamzaabovearabic = 0x0626;
  t.yehhamzaabovefinalarabic = 0xfe8a;
  t.yehhamzaaboveinitialarabic = 0xfe8b;
  t.yehhamzaabovemedialarabic = 0xfe8c;
  t.yehinitialarabic = 0xfef3;
  t.yehmedialarabic = 0xfef4;
  t.yehmeeminitialarabic = 0xfcdd;
  t.yehmeemisolatedarabic = 0xfc58;
  t.yehnoonfinalarabic = 0xfc94;
  t.yehthreedotsbelowarabic = 0x06d1;
  t.yekorean = 0x3156;
  t.yen = 0x00a5;
  t.yenmonospace = 0xffe5;
  t.yeokorean = 0x3155;
  t.yeorinhieuhkorean = 0x3186;
  t.yerahbenyomohebrew = 0x05aa;
  t.yerahbenyomolefthebrew = 0x05aa;
  t.yericyrillic = 0x044b;
  t.yerudieresiscyrillic = 0x04f9;
  t.yesieungkorean = 0x3181;
  t.yesieungpansioskorean = 0x3183;
  t.yesieungsioskorean = 0x3182;
  t.yetivhebrew = 0x059a;
  t.ygrave = 0x1ef3;
  t.yhook = 0x01b4;
  t.yhookabove = 0x1ef7;
  t.yiarmenian = 0x0575;
  t.yicyrillic = 0x0457;
  t.yikorean = 0x3162;
  t.yinyang = 0x262f;
  t.yiwnarmenian = 0x0582;
  t.ymonospace = 0xff59;
  t.yod = 0x05d9;
  t.yoddagesh = 0xfb39;
  t.yoddageshhebrew = 0xfb39;
  t.yodhebrew = 0x05d9;
  t.yodyodhebrew = 0x05f2;
  t.yodyodpatahhebrew = 0xfb1f;
  t.yohiragana = 0x3088;
  t.yoikorean = 0x3189;
  t.yokatakana = 0x30e8;
  t.yokatakanahalfwidth = 0xff96;
  t.yokorean = 0x315b;
  t.yosmallhiragana = 0x3087;
  t.yosmallkatakana = 0x30e7;
  t.yosmallkatakanahalfwidth = 0xff6e;
  t.yotgreek = 0x03f3;
  t.yoyaekorean = 0x3188;
  t.yoyakorean = 0x3187;
  t.yoyakthai = 0x0e22;
  t.yoyingthai = 0x0e0d;
  t.yparen = 0x24b4;
  t.ypogegrammeni = 0x037a;
  t.ypogegrammenigreekcmb = 0x0345;
  t.yr = 0x01a6;
  t.yring = 0x1e99;
  t.ysuperior = 0x02b8;
  t.ytilde = 0x1ef9;
  t.yturned = 0x028e;
  t.yuhiragana = 0x3086;
  t.yuikorean = 0x318c;
  t.yukatakana = 0x30e6;
  t.yukatakanahalfwidth = 0xff95;
  t.yukorean = 0x3160;
  t.yusbigcyrillic = 0x046b;
  t.yusbigiotifiedcyrillic = 0x046d;
  t.yuslittlecyrillic = 0x0467;
  t.yuslittleiotifiedcyrillic = 0x0469;
  t.yusmallhiragana = 0x3085;
  t.yusmallkatakana = 0x30e5;
  t.yusmallkatakanahalfwidth = 0xff6d;
  t.yuyekorean = 0x318b;
  t.yuyeokorean = 0x318a;
  t.yyabengali = 0x09df;
  t.yyadeva = 0x095f;
  t.z = 0x007a;
  t.zaarmenian = 0x0566;
  t.zacute = 0x017a;
  t.zadeva = 0x095b;
  t.zagurmukhi = 0x0a5b;
  t.zaharabic = 0x0638;
  t.zahfinalarabic = 0xfec6;
  t.zahinitialarabic = 0xfec7;
  t.zahiragana = 0x3056;
  t.zahmedialarabic = 0xfec8;
  t.zainarabic = 0x0632;
  t.zainfinalarabic = 0xfeb0;
  t.zakatakana = 0x30b6;
  t.zaqefgadolhebrew = 0x0595;
  t.zaqefqatanhebrew = 0x0594;
  t.zarqahebrew = 0x0598;
  t.zayin = 0x05d6;
  t.zayindagesh = 0xfb36;
  t.zayindageshhebrew = 0xfb36;
  t.zayinhebrew = 0x05d6;
  t.zbopomofo = 0x3117;
  t.zcaron = 0x017e;
  t.zcircle = 0x24e9;
  t.zcircumflex = 0x1e91;
  t.zcurl = 0x0291;
  t.zdot = 0x017c;
  t.zdotaccent = 0x017c;
  t.zdotbelow = 0x1e93;
  t.zecyrillic = 0x0437;
  t.zedescendercyrillic = 0x0499;
  t.zedieresiscyrillic = 0x04df;
  t.zehiragana = 0x305c;
  t.zekatakana = 0x30bc;
  t.zero = 0x0030;
  t.zeroarabic = 0x0660;
  t.zerobengali = 0x09e6;
  t.zerodeva = 0x0966;
  t.zerogujarati = 0x0ae6;
  t.zerogurmukhi = 0x0a66;
  t.zerohackarabic = 0x0660;
  t.zeroinferior = 0x2080;
  t.zeromonospace = 0xff10;
  t.zerooldstyle = 0xf730;
  t.zeropersian = 0x06f0;
  t.zerosuperior = 0x2070;
  t.zerothai = 0x0e50;
  t.zerowidthjoiner = 0xfeff;
  t.zerowidthnonjoiner = 0x200c;
  t.zerowidthspace = 0x200b;
  t.zeta = 0x03b6;
  t.zhbopomofo = 0x3113;
  t.zhearmenian = 0x056a;
  t.zhebrevecyrillic = 0x04c2;
  t.zhecyrillic = 0x0436;
  t.zhedescendercyrillic = 0x0497;
  t.zhedieresiscyrillic = 0x04dd;
  t.zihiragana = 0x3058;
  t.zikatakana = 0x30b8;
  t.zinorhebrew = 0x05ae;
  t.zlinebelow = 0x1e95;
  t.zmonospace = 0xff5a;
  t.zohiragana = 0x305e;
  t.zokatakana = 0x30be;
  t.zparen = 0x24b5;
  t.zretroflexhook = 0x0290;
  t.zstroke = 0x01b6;
  t.zuhiragana = 0x305a;
  t.zukatakana = 0x30ba;
  t[".notdef"] = 0x0000;

  // TeX-specific glyph names.
  t.angbracketleftbig = 0x2329;
  t.angbracketleftBig = 0x2329;
  t.angbracketleftbigg = 0x2329;
  t.angbracketleftBigg = 0x2329;
  t.angbracketrightBig = 0x232a;
  t.angbracketrightbig = 0x232a;
  t.angbracketrightBigg = 0x232a;
  t.angbracketrightbigg = 0x232a;
  t.arrowhookleft = 0x21aa;
  t.arrowhookright = 0x21a9;
  t.arrowlefttophalf = 0x21bc;
  t.arrowleftbothalf = 0x21bd;
  t.arrownortheast = 0x2197;
  t.arrownorthwest = 0x2196;
  t.arrowrighttophalf = 0x21c0;
  t.arrowrightbothalf = 0x21c1;
  t.arrowsoutheast = 0x2198;
  t.arrowsouthwest = 0x2199;
  t.backslashbig = 0x2216;
  t.backslashBig = 0x2216;
  t.backslashBigg = 0x2216;
  t.backslashbigg = 0x2216;
  t.bardbl = 0x2016;
  t.bracehtipdownleft = 0xfe37;
  t.bracehtipdownright = 0xfe37;
  t.bracehtipupleft = 0xfe38;
  t.bracehtipupright = 0xfe38;
  t.braceleftBig = 0x007b;
  t.braceleftbig = 0x007b;
  t.braceleftbigg = 0x007b;
  t.braceleftBigg = 0x007b;
  t.bracerightBig = 0x007d;
  t.bracerightbig = 0x007d;
  t.bracerightbigg = 0x007d;
  t.bracerightBigg = 0x007d;
  t.bracketleftbig = 0x005b;
  t.bracketleftBig = 0x005b;
  t.bracketleftbigg = 0x005b;
  t.bracketleftBigg = 0x005b;
  t.bracketrightBig = 0x005d;
  t.bracketrightbig = 0x005d;
  t.bracketrightbigg = 0x005d;
  t.bracketrightBigg = 0x005d;
  t.ceilingleftbig = 0x2308;
  t.ceilingleftBig = 0x2308;
  t.ceilingleftBigg = 0x2308;
  t.ceilingleftbigg = 0x2308;
  t.ceilingrightbig = 0x2309;
  t.ceilingrightBig = 0x2309;
  t.ceilingrightbigg = 0x2309;
  t.ceilingrightBigg = 0x2309;
  t.circledotdisplay = 0x2299;
  t.circledottext = 0x2299;
  t.circlemultiplydisplay = 0x2297;
  t.circlemultiplytext = 0x2297;
  t.circleplusdisplay = 0x2295;
  t.circleplustext = 0x2295;
  t.contintegraldisplay = 0x222e;
  t.contintegraltext = 0x222e;
  t.coproductdisplay = 0x2210;
  t.coproducttext = 0x2210;
  t.floorleftBig = 0x230a;
  t.floorleftbig = 0x230a;
  t.floorleftbigg = 0x230a;
  t.floorleftBigg = 0x230a;
  t.floorrightbig = 0x230b;
  t.floorrightBig = 0x230b;
  t.floorrightBigg = 0x230b;
  t.floorrightbigg = 0x230b;
  t.hatwide = 0x0302;
  t.hatwider = 0x0302;
  t.hatwidest = 0x0302;
  t.intercal = 0x1d40;
  t.integraldisplay = 0x222b;
  t.integraltext = 0x222b;
  t.intersectiondisplay = 0x22c2;
  t.intersectiontext = 0x22c2;
  t.logicalanddisplay = 0x2227;
  t.logicalandtext = 0x2227;
  t.logicalordisplay = 0x2228;
  t.logicalortext = 0x2228;
  t.parenleftBig = 0x0028;
  t.parenleftbig = 0x0028;
  t.parenleftBigg = 0x0028;
  t.parenleftbigg = 0x0028;
  t.parenrightBig = 0x0029;
  t.parenrightbig = 0x0029;
  t.parenrightBigg = 0x0029;
  t.parenrightbigg = 0x0029;
  t.prime = 0x2032;
  t.productdisplay = 0x220f;
  t.producttext = 0x220f;
  t.radicalbig = 0x221a;
  t.radicalBig = 0x221a;
  t.radicalBigg = 0x221a;
  t.radicalbigg = 0x221a;
  t.radicalbt = 0x221a;
  t.radicaltp = 0x221a;
  t.radicalvertex = 0x221a;
  t.slashbig = 0x002f;
  t.slashBig = 0x002f;
  t.slashBigg = 0x002f;
  t.slashbigg = 0x002f;
  t.summationdisplay = 0x2211;
  t.summationtext = 0x2211;
  t.tildewide = 0x02dc;
  t.tildewider = 0x02dc;
  t.tildewidest = 0x02dc;
  t.uniondisplay = 0x22c3;
  t.unionmultidisplay = 0x228e;
  t.unionmultitext = 0x228e;
  t.unionsqdisplay = 0x2294;
  t.unionsqtext = 0x2294;
  t.uniontext = 0x22c3;
  t.vextenddouble = 0x2225;
  t.vextendsingle = 0x2223;
});

const getDingbatsGlyphsUnicode = getLookupTableFactory(function (t) {
  t.space = 0x0020;
  t.a1 = 0x2701;
  t.a2 = 0x2702;
  t.a202 = 0x2703;
  t.a3 = 0x2704;
  t.a4 = 0x260e;
  t.a5 = 0x2706;
  t.a119 = 0x2707;
  t.a118 = 0x2708;
  t.a117 = 0x2709;
  t.a11 = 0x261b;
  t.a12 = 0x261e;
  t.a13 = 0x270c;
  t.a14 = 0x270d;
  t.a15 = 0x270e;
  t.a16 = 0x270f;
  t.a105 = 0x2710;
  t.a17 = 0x2711;
  t.a18 = 0x2712;
  t.a19 = 0x2713;
  t.a20 = 0x2714;
  t.a21 = 0x2715;
  t.a22 = 0x2716;
  t.a23 = 0x2717;
  t.a24 = 0x2718;
  t.a25 = 0x2719;
  t.a26 = 0x271a;
  t.a27 = 0x271b;
  t.a28 = 0x271c;
  t.a6 = 0x271d;
  t.a7 = 0x271e;
  t.a8 = 0x271f;
  t.a9 = 0x2720;
  t.a10 = 0x2721;
  t.a29 = 0x2722;
  t.a30 = 0x2723;
  t.a31 = 0x2724;
  t.a32 = 0x2725;
  t.a33 = 0x2726;
  t.a34 = 0x2727;
  t.a35 = 0x2605;
  t.a36 = 0x2729;
  t.a37 = 0x272a;
  t.a38 = 0x272b;
  t.a39 = 0x272c;
  t.a40 = 0x272d;
  t.a41 = 0x272e;
  t.a42 = 0x272f;
  t.a43 = 0x2730;
  t.a44 = 0x2731;
  t.a45 = 0x2732;
  t.a46 = 0x2733;
  t.a47 = 0x2734;
  t.a48 = 0x2735;
  t.a49 = 0x2736;
  t.a50 = 0x2737;
  t.a51 = 0x2738;
  t.a52 = 0x2739;
  t.a53 = 0x273a;
  t.a54 = 0x273b;
  t.a55 = 0x273c;
  t.a56 = 0x273d;
  t.a57 = 0x273e;
  t.a58 = 0x273f;
  t.a59 = 0x2740;
  t.a60 = 0x2741;
  t.a61 = 0x2742;
  t.a62 = 0x2743;
  t.a63 = 0x2744;
  t.a64 = 0x2745;
  t.a65 = 0x2746;
  t.a66 = 0x2747;
  t.a67 = 0x2748;
  t.a68 = 0x2749;
  t.a69 = 0x274a;
  t.a70 = 0x274b;
  t.a71 = 0x25cf;
  t.a72 = 0x274d;
  t.a73 = 0x25a0;
  t.a74 = 0x274f;
  t.a203 = 0x2750;
  t.a75 = 0x2751;
  t.a204 = 0x2752;
  t.a76 = 0x25b2;
  t.a77 = 0x25bc;
  t.a78 = 0x25c6;
  t.a79 = 0x2756;
  t.a81 = 0x25d7;
  t.a82 = 0x2758;
  t.a83 = 0x2759;
  t.a84 = 0x275a;
  t.a97 = 0x275b;
  t.a98 = 0x275c;
  t.a99 = 0x275d;
  t.a100 = 0x275e;
  t.a101 = 0x2761;
  t.a102 = 0x2762;
  t.a103 = 0x2763;
  t.a104 = 0x2764;
  t.a106 = 0x2765;
  t.a107 = 0x2766;
  t.a108 = 0x2767;
  t.a112 = 0x2663;
  t.a111 = 0x2666;
  t.a110 = 0x2665;
  t.a109 = 0x2660;
  t.a120 = 0x2460;
  t.a121 = 0x2461;
  t.a122 = 0x2462;
  t.a123 = 0x2463;
  t.a124 = 0x2464;
  t.a125 = 0x2465;
  t.a126 = 0x2466;
  t.a127 = 0x2467;
  t.a128 = 0x2468;
  t.a129 = 0x2469;
  t.a130 = 0x2776;
  t.a131 = 0x2777;
  t.a132 = 0x2778;
  t.a133 = 0x2779;
  t.a134 = 0x277a;
  t.a135 = 0x277b;
  t.a136 = 0x277c;
  t.a137 = 0x277d;
  t.a138 = 0x277e;
  t.a139 = 0x277f;
  t.a140 = 0x2780;
  t.a141 = 0x2781;
  t.a142 = 0x2782;
  t.a143 = 0x2783;
  t.a144 = 0x2784;
  t.a145 = 0x2785;
  t.a146 = 0x2786;
  t.a147 = 0x2787;
  t.a148 = 0x2788;
  t.a149 = 0x2789;
  t.a150 = 0x278a;
  t.a151 = 0x278b;
  t.a152 = 0x278c;
  t.a153 = 0x278d;
  t.a154 = 0x278e;
  t.a155 = 0x278f;
  t.a156 = 0x2790;
  t.a157 = 0x2791;
  t.a158 = 0x2792;
  t.a159 = 0x2793;
  t.a160 = 0x2794;
  t.a161 = 0x2192;
  t.a163 = 0x2194;
  t.a164 = 0x2195;
  t.a196 = 0x2798;
  t.a165 = 0x2799;
  t.a192 = 0x279a;
  t.a166 = 0x279b;
  t.a167 = 0x279c;
  t.a168 = 0x279d;
  t.a169 = 0x279e;
  t.a170 = 0x279f;
  t.a171 = 0x27a0;
  t.a172 = 0x27a1;
  t.a173 = 0x27a2;
  t.a162 = 0x27a3;
  t.a174 = 0x27a4;
  t.a175 = 0x27a5;
  t.a176 = 0x27a6;
  t.a177 = 0x27a7;
  t.a178 = 0x27a8;
  t.a179 = 0x27a9;
  t.a193 = 0x27aa;
  t.a180 = 0x27ab;
  t.a199 = 0x27ac;
  t.a181 = 0x27ad;
  t.a200 = 0x27ae;
  t.a182 = 0x27af;
  t.a201 = 0x27b1;
  t.a183 = 0x27b2;
  t.a184 = 0x27b3;
  t.a197 = 0x27b4;
  t.a185 = 0x27b5;
  t.a194 = 0x27b6;
  t.a198 = 0x27b7;
  t.a186 = 0x27b8;
  t.a195 = 0x27b9;
  t.a187 = 0x27ba;
  t.a188 = 0x27bb;
  t.a189 = 0x27bc;
  t.a190 = 0x27bd;
  t.a191 = 0x27be;
  t.a89 = 0x2768; // 0xF8D7
  t.a90 = 0x2769; // 0xF8D8
  t.a93 = 0x276a; // 0xF8D9
  t.a94 = 0x276b; // 0xF8DA
  t.a91 = 0x276c; // 0xF8DB
  t.a92 = 0x276d; // 0xF8DC
  t.a205 = 0x276e; // 0xF8DD
  t.a85 = 0x276f; // 0xF8DE
  t.a206 = 0x2770; // 0xF8DF
  t.a86 = 0x2771; // 0xF8E0
  t.a87 = 0x2772; // 0xF8E1
  t.a88 = 0x2773; // 0xF8E2
  t.a95 = 0x2774; // 0xF8E3
  t.a96 = 0x2775; // 0xF8E4
  t[".notdef"] = 0x0000;
});

export { getDingbatsGlyphsUnicode, getGlyphsUnicode };
